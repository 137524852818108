import * as React from 'react';
import {TimelineEventEditPage} from "../pages/timeline/TimelineEventEditPage";
import {TimelineTagListingPage} from "../pages/timeline/TimelineTagListing.page";

export const timelineRoutes = [
    {
        path: '/timeline/create',
        component: TimelineEventEditPage,
        isPrivate: true
    },
    {
        path: '/timeline/tags',
        component: TimelineTagListingPage,
        isPrivate: true
    }
]