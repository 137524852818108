import * as React from 'react';
import {ITimeline, ITimelineEvent} from "../../../interfaces/Timeline";
import {QuarterSplitTemplate} from "../../templates/QuarterSplitTemplate";
import {TimelineYears} from "../molecules/TimelineYears";
import {TimelineEvents} from "../molecules/TimelineEvents";
import {ITimelineState, useTimeline} from "../../../states/timeline/useTimeline";
import {TimelineTitle} from "../atoms/TimelineTitle";

export const Timeline = (props:ITimeline, state:ITimelineState) => {
    const {events, loading, years, title} = useTimeline(props.slug);
    const timelineYearSelector = (
        <>
            <TimelineYears years={years} loading={loading}/>
        </>
    )
    const timelineEvents = <TimelineEvents events={events} loading={loading}/>

    return (
        <>
            <QuarterSplitTemplate minorContent={timelineYearSelector} majorContent={timelineEvents} title={title}/>
        </>

    )
}