import * as React from 'react';
import {Col, Row} from "reactstrap";

type myProps = {
    minorContent:any,
    majorContent:any,
    title?:string
}

export const QuarterSplitTemplate = (props:myProps) => {
    return (
        <>
            <div className={"container-fluid min-screen-height"}>
                <Row>
                    <Col md={2} className={"bg-fmis-primary"}/>
                    <Col md={10}>{props.title?(<h2 className={"p-2"}>{props.title}</h2>):''}</Col>
                </Row>
                <div className={"row min-vh-100"}>
                    <div className={"col-3 bg-fmis-primary"}>{props.minorContent}</div>
                    <div className={"col-9"}>{props.majorContent}</div>
                </div>
            </div>
        </>
    )
}