import * as React from 'react';
import {useEffect, useState} from "react";
import Api from "../../config/Api";
import {ILodge} from "../../interfaces/Lodge";

export type ILodgesState = {
    lodges: ILodge[],
    loading: boolean
}

export const useLodges = ():ILodgesState => {
    const [lodges, setLodges] = useState<ILodge[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        Api
            .get('lodges')
            .then(
                res => {
                    setLodges(res.data);
                    setLoading(false);
                }
            )
            .catch(
                err => {
                    setLoading(false);
                }
            )
    }, [])

    return {lodges, loading}
}