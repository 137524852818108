import * as React from 'react';
import {Header} from "../layout/Header";
import {Footer} from "../layout/Footer";

type myProps = {
    content: object,
    className?: string
}

export const FullWidthTemplate = (props:myProps) => {
    return (
        <>
            <Header/>
            <div className={props.className}>
                {props.content}
            </div>
            <Footer/>
        </>
    )
}