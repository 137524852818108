import * as React from 'react';
import {ITimelineTag} from "../../../interfaces/Timeline";
import {Component, useState} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {TimelineTagLink} from "./TimelineTagLink";
import {useModal} from "../../../states/components/useModal";

type MyProps = {
    tags: ITimelineTag[],
    color: string
};

const defaultProps = {
    tags: []
}

export const TimelineTagModal = (props:MyProps) => {
    const {isOpen, toggle} = useModal(false);

    return (
        <>
            <Button color={props.color} onClick={toggle} block>View Tags</Button>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Event Tags</ModalHeader>
                <ModalBody>
                    {props.tags.map((tag, i)=>{return <TimelineTagLink key={i} color={props.color} location={tag.slug} content={tag.name}/>})}
                </ModalBody>
            </Modal>
        </>
    )
}

TimelineTagModal.defaultProps = defaultProps;