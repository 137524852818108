import * as React from 'react';
import {ProfileQuickMenu} from "../../user/molecules/ProfileQuickMenu";

export const DashboardNotificationBar = () => {
    return (
        <div className={"d-flex w-100 justify-content-between"}>
            <div></div>
            <div className={""}>
                <ProfileQuickMenu/>
            </div>
        </div>
    )
}