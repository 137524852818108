import * as React from 'react';

type MyProps = {
    consecrated_year: string
}

export const LodgeProfileMetaTitle = (props:MyProps) => {
    const date_year = new Date(props.consecrated_year);

    return (
        <p className={"text-muted"}>First opened (founded) in {date_year.getFullYear()}.</p>
    )
}