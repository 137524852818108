export class PaginationHelper {
    public items:any[] = [];
    public currentPage:number = 0;
    public limit:number = 25;

    public constructor(items:any[], limitPerPage:number = 25, currentPage:number = 0) {
        this.limit = limitPerPage;
        this.currentPage = currentPage;
        this.sortIntoPages(items);
    }

    private sortIntoPages(suppliedArr:any[]) {
        let arr = [...suppliedArr];
        while (arr.length > 0) {
            const deletedItems = [arr.splice(0, this.limit)];
            this.items.push(deletedItems);
        }
    }

    public returnPage(pageNumber:number) {
        if(pageNumber > this.items.length) {
            this.currentPage = this.items.length;
            return this.items[this.items.length-1];
        } else {
            this.currentPage = pageNumber;
            return this.items[pageNumber-1];
        }
    }
}