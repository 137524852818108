import * as React from 'react';
import {IProfile} from "../../../interfaces/User";
import {DateTime} from "luxon";
import {ListGroup, ListGroupItem} from "reactstrap";
import {useTimeline} from "../../../states/timeline/useTimeline";
import {TimelineEvents} from "../../timeline/molecules/TimelineEvents";


export const Profile = ({profile}:{profile:IProfile}) => {
    const initiationDate = (typeof profile.masonic_profile?.initiation !== 'undefined')?DateTime.fromISO(profile.masonic_profile?.initiation):'N/A';
    const passedDate = (typeof profile.masonic_profile?.passing !== 'undefined')?DateTime.fromISO(profile.masonic_profile?.passing):'N/A';
    const raisedDate = (typeof profile.masonic_profile?.raising !== 'undefined')?DateTime.fromISO(profile.masonic_profile?.raising):'N/A';
    const {
        events,
        loading
    } = useTimeline(profile.slug + "_" + profile.masonic_profile?.mother_lodge?.number);
    return (
        <>
            <h1 className={"my-5"}>{profile.military_profile?.rank} {profile.first_name} {profile.last_name}</h1>
            <h4>Occupation: {profile.masonic_profile?.occupation}</h4>
            <h4 className={"my-4"}>Mother Lodge: {profile.masonic_profile?.mother_lodge?.name} {profile.masonic_profile?.mother_lodge?.number}</h4>
            <ListGroup horizontal>
                <ListGroupItem>Initiated: {initiationDate.toLocaleString(DateTime.DATE_HUGE)}</ListGroupItem>
                <ListGroupItem>Passed: {passedDate.toLocaleString(DateTime.DATE_HUGE)}</ListGroupItem>
                <ListGroupItem>Raised: {raisedDate.toLocaleString(DateTime.DATE_HUGE)}</ListGroupItem>
            </ListGroup>
            <h4 className={"my-4"}>Regiment: {profile.military_profile?.regiment}</h4>
            <TimelineEvents events={events} loading={loading}/>
        </>
    )
}