import {Spinner} from "reactstrap";

export const LoadingOrganism = () => {
    return (
        <>
            <div className={"container-fluid screen-sandwich"}>
                <div className={"row screen-sandwich"}>
                    <div className={"col text-center mt-5"}>
                        <Spinner/>
                    </div>
                </div>
            </div>
        </>
    )
}