import * as React from 'react';
import {ProfileCreatePage} from "../pages/profile/ProfileCreatePage";
import {MyProfilePage} from "../pages/profile/MyProfilePage";

export const profileRoutes = [
    {
        path: '/my/profile',
        component: MyProfilePage,
        isPrivate: true
    },
    {
        path: '/profile/manage',
        component: ProfileCreatePage,
        isPrivate: true
    }
]