import * as React from 'react';
import {useEffect} from "react";
import Api from "../../config/Api";
import {ITimelineEvent, ITimelineYear} from "../../interfaces/Timeline";
import {DateTime} from "luxon";

export type ITimelineState = {
    events: ITimelineEvent[],
    loading: boolean,
    years: ITimelineYear[],
    title: string
}

export const useTimeline = (slug:string = ""):ITimelineState => {
    const [events, setEvents] = React.useState<ITimelineEvent[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [years, setYears] = React.useState<ITimelineYear[]>([]);
    const [title, setTitle] = React.useState<string>("")

    useEffect(() => {
        if(slug.trim() === "") return
        Api
            .get('timeline/'+slug)
            .then(
                res => {
                    // const resEvents = res.data.events;
                    const resEvents:ITimelineEvent = res.data.events.map((ev: {[key:string]:any, date: string }) => {return {...ev, date:DateTime.fromISO(ev.date)}})
                    //@ts-ignore
                    setEvents(resEvents.sort((a, b)=>a.date>b.date?1:-1));
                    setLoading(false);
                    setTitle(res.data.tag.name)
                }
            )
            .catch(
                err => {
                    console.log(err);
                    setLoading(false)
                }
            )
    }, [])

    useEffect(() => {
        const yrsToSet:ITimelineYear[] = [];
        events.map(event => {
            // const date = event.date;
            yrsToSet.push({
                id: event._id,
                year: event.date.year.toString(),
                active: false
            })
        })
        setYears(yrsToSet);
    }, [events]);

    return {events, loading, years, title};
}