import {ILodge} from "../../../interfaces/Lodge";
import {useLodgeSelector} from "../../../states/lodge/useLodgeSelector";
import {Input} from "reactstrap";

export const LodgeSelector = ({givenLodges=[], multiple=false, onChangeFunc}:{givenLodges:ILodge[], multiple:boolean, onChangeFunc:(lodge:ILodge[])=>void}) => {
    const {
        lodges,
        selectedLodges,
        changeSelectedLodge
    } = useLodgeSelector(givenLodges, multiple, onChangeFunc);

    return (
        <>
            <Input type={"select"} onChange={changeSelectedLodge} multiple={multiple}>
                {!multiple?<option value={0}>Please select...</option>:''}
                {lodges.map(lodge => {
                    if(lodge && selectedLodges.includes(lodge)) {
                        return <option key={lodge.slug} value={lodge.slug} selected>{lodge.name}</option>
                    }
                    return <option key={lodge.slug} value={lodge.slug}>{lodge.name}</option>
                })}
            </Input>
        </>
    )
}