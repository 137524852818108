import ProfileForm from "../../components/user/molecules/ProfileForm";
import {UserDashboardTemplate} from "../../components/templates/UserDashboardTemplate";
import * as React from "react";
import {useAuthState} from "../../contexts/auth/authContext";

export const MyProfilePage = () => {
    const {user} = useAuthState();
    console.log(user)

    const pageContent = (
        <>
            <h1>Your Profile</h1>
            <ProfileForm user={user} edit_dod={false}/>
        </>
    )

    return (
        <UserDashboardTemplate content={pageContent}/>
    )
}