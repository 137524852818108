import * as React from 'react';

type myProps = {
    barContent: any,
    pageContent: any
}

export const TopBarTemplate = (props:myProps) => {
    return (
        <div className={"container-fluid"}>
            <div className={"w-100"}>{props.barContent}</div>
            <div className={"w-100"}>{props.pageContent}</div>
        </div>
    )
}