import * as React from 'react';
import {IUser} from "../interfaces/User";
import {Reducer} from "react";

export type authState = {
    user?: IUser,
    token?: string,
    loading: boolean,
    errorMessage?: string
}

export type authActionTypes =
    {type: 'REQUEST_LOGIN', payload: null} |
    {type: 'LOGIN_SUCCESS', payload: {user:IUser, token:string|undefined}} |
    {type: 'LOGOUT', payload: null} |
    {type: 'LOGIN_ERROR', payload: string};

//@ts-ignore
let user = localStorage.getItem('currentUser')?JSON.parse(localStorage.getItem('currentUser')): undefined;
//@ts-ignore
let token = localStorage.getItem('token')?localStorage.getItem('token'): undefined;
if(token === null) {
    token = undefined;
}

export const initialState:authState = {
    loading: false,
    user: user,
    token: token
}

export const AuthReducer:Reducer<authState, authActionTypes> = (initialState, action) => {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                user: action.payload.user,
                token: action.payload.token,
                loading: false
            };
        case "LOGOUT":
            return {
                ...initialState,
                user: undefined,
                token: undefined
            };
        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.payload
            };
    }
}
