import * as React from 'react';
import {LoginPage} from "../pages/account/LoginPage";
import {RegistrationPage} from "../pages/account/RegistrationPage";
import {DashboardRegistrationPage} from "../pages/account/DashboardRegistrationPage";

export const accountRoutes = [
    {
        path: '/login',
        component: LoginPage
    },
    // {
    //     path: '/registration',
    //     component: RegistrationPage
    // },
    {
        path: '/account/registration',
        component: DashboardRegistrationPage
    }
    // {
    //     path: 'logout',
    //     component:
    // }
]