import * as React from 'react';
import {useState} from "react";
import {IProfile, IUser} from "../../interfaces/User";
import Api from "../../config/Api";
import {useAuthDispatch, useAuthState} from "../../contexts/auth/authContext";
import {DateTime} from "luxon";
import {updateCurrent} from "../../contexts/auth/actions";

export const useProfileForm = (profile:IProfile|undefined, self:boolean=true) => {
    const [user_id, setUserId] = useState(profile !== undefined?profile._id : '');
    const [first_name, setFirstName] = useState(profile !== undefined?profile?.first_name : '');
    const [last_name, setLastName] = useState(profile !== undefined?profile?.last_name : '');
    const [date_of_birth, setDateOfBirth] = useState(profile !== undefined?profile?.date_of_birth : '');
    const [date_of_death, setDateOfDeath] = useState(profile !== undefined?profile?.date_of_death : '');
    const [contact_number, setContactNumber] = useState(profile !== undefined?profile?.contact_number : '');
    const [loading, setLoading] = useState(false);

    const firstNameChange = (e:any) => setFirstName(e.target.value)
    const lastNameChange = (e:any) => setLastName(e.target.value)
    const dobChange = (date:DateTime) => setDateOfBirth(date.toLocaleString())
    const dodChange = (date:DateTime) => setDateOfDeath(date.toLocaleString())
    const numberChange = (e:any) => setContactNumber(e.target.value)

    const dispatch = useAuthDispatch();
    const authUser = useAuthState();

    const clearForm = () => {
        setFirstName('');
        setLastName('');
        setDateOfBirth('');
        setDateOfDeath('');
        setContactNumber('');
    }

    const submit = () => {
        const postData = {
            first_name: first_name,
            last_name: last_name,
            contact_number: contact_number,
        }

        if(date_of_birth) {
            let dt = DateTime.fromFormat(date_of_birth, 'dd/MM/yyyy');
            //@ts-ignore
            postData.date_of_birth = dt.toISODate();
        }
        if(date_of_death) {
            let dt = DateTime.fromFormat(date_of_death, 'dd/MM/yyyy');
            //@ts-ignore
            postData.date_of_death = dt.toISODate();
        }

        try {
            setLoading(true);
            const url = (self)?'/profile/createSelf':'/profile/create';

            updateCurrent(dispatch, authUser.token);
            setLoading(false);
            return;
            Api
                .post(url, postData, {
                    headers: {
                        'Authorization': `Token ${authUser.token}`
                    }
                })
                .then(
                    res => {
                        setLoading(false);
                        if(!self){clearForm()}

                    }
                )
                .catch(
                    err => {
                        console.log(err);
                        setLoading(false);
                    }
                )
        } catch (e) {

        }
    }

    return {
        first_name,
        last_name,
        date_of_birth,
        date_of_death,
        contact_number,
        loading,
        submit,
        firstNameChange,
        lastNameChange,
        dobChange,
        dodChange,
        numberChange
    }
}