import {IPortrait} from "../../../interfaces/User";
import {usePortraitForm} from "../../../states/portrait/usePortraitForm";
import {CustomInput, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {AddressForm} from "../../address/forms/address.form";
import {DefaultAddress} from "../../../defaults/address.default";
import {DateSelector} from "../../misc/forms/organisms/DateSelector";
import {DateTime} from "luxon";
import {ArtistForm} from "../../artist/forms/artist.form";

export const PortraitForm = ({givenPortrait}:{givenPortrait:IPortrait}) => {
    const {
        updateValue,
        updatePresentedDate,
        thisPortrait,
        errors
    } = usePortraitForm({givenPortrait:givenPortrait});

    return (
        <>
            <Form>
                <FormGroup>
                    <Label for={"image"}>Image*</Label>
                    <CustomInput id={"image"} type={"file"} value={givenPortrait.image} onChange={updateValue} valid={errors.hasOwnProperty('image')}/>
                    <FormFeedback>{errors.hasOwnProperty('image') && errors.image}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for={"financed_by"}>Financed By</Label>
                    <Input type={"text"} value={givenPortrait.financed} onChange={updateValue} placeholder={"Members of Lodge 360"}/>
                </FormGroup>
                <FormGroup>
                    <Label>Presented On</Label>
                    <DateSelector onChangeFunc={updatePresentedDate} givenDate={DateTime.fromISO(givenPortrait.presented || DateTime.now().toISODate())}/>
                </FormGroup>
            </Form>
            <h3>Artist details</h3>
            <ArtistForm givenArtist={thisPortrait.artist}/>
        </>

    )
}