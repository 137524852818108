import * as React from 'react';

export const Footer = () => {
    return (
        <div className={"row mx-0"}>
            <div className={"col-12 my-3 col-md-4"}>
                <h3 className={"text-center"}>Charity</h3>
                <a href={"https://mcf.org.uk"}>
                    <img className={"img-fluid mx-auto d-block w-50"} src={process.env.PUBLIC_URL + "/MCF-Logo-RGB-AW.jpg"} alt={"Masonic Charity Fund logo"}/>
                </a>
            </div>
            <div className={"col-12 my-3 col-md-4"}>
                <h3 className={"text-center"}>Our Provinces</h3>
                <div className={"row justify-content-around text-center"}>
                    <a href={"https://www.wrprovince.org.uk"}>
                        <img className={"img-fluid mx-auto d-inline-block w-75"} src={process.env.PUBLIC_URL + "/yorkshiremasons-logo.png"} alt={"Provincial Grand Lodge of Yorkshire West Riding logo"}/>
                    </a>
                    <a href={"https://www.derbyshiremason.org"}>
                        <img className={"img-fluid mx-auto d-inline-block w-75"} src={process.env.PUBLIC_URL + "/PGLDerbyshireBadge.png"} alt={"Provincial Grand Lodge of Derbyshire logo"}/>
                    </a>
                </div>
            </div>
            <div className={"col-12 my-3 col-md-4"}>
                <h3 className={"text-center"}>Our Grand Lodge</h3>
                <a href={"https://www.ugle.org.uk"}>
                    <img className={"img-fluid mx-auto d-block w-50"} src={process.env.PUBLIC_URL + '/ugle_logo.png'} alt={"United Grand Lodge of England logo"}/>
                </a>
            </div>
        </div>
    );
}