import * as React from 'react';
import {ICalendarEvent} from "../../../interfaces/Calendar";
import Calendar from "react-calendar";
import {useCalendar} from "../../../states/components/useCalendar";
import {CalendarEventModal} from "../molecules/CalendarEventModal";
import "../../../styles/react_calendar.scss";

type MyProps = {
    events: ICalendarEvent[] | []
}

export const CalendarOrganism = (props:MyProps) => {
    const {value, onChange} = useCalendar();

    // @ts-ignore
    const currentDateData = ({date, view}) => {
        if(view === 'month') {
            const day_name = new Intl.DateTimeFormat('en-GB', {weekday:'long'}).format(date);
            let day_content = <div className={"day-text"}>{date.getDate()}<small>{day_name}</small></div>;

            let filteredEvents = props.events.filter(event => {
                const eventDate = new Date(event.date_time);
                eventDate.setHours(0,0,0,0);
                return eventDate.valueOf() === date.valueOf();
            })

            filteredEvents.map(filteredEvent => {
                return day_content = (
                    <>
                        {day_content}
                        <div className={"calendar-event"}>
                            <CalendarEventModal {...filteredEvent}/>
                        </div>
                    </>
                )
            })
            return day_content;
        }
    }

    // @ts-ignore
    return <Calendar showNeighboringMonth={false} minDetail={"decade"} className={"main-calendar"} value={value} onChange={onChange} tileContent={currentDateData}/>
}