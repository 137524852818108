import * as React from 'react';
import {IUser} from "../../interfaces/User";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useAuthState} from "../../contexts/auth/authContext";

type myProps = {
    groups_permitted?: string[],
    authentication_path: string
} & RouteProps

export const ProtectedRoute = ({authentication_path, groups_permitted, ...routeProps}:myProps) => {
    const user = useAuthState();
    const canViewRoute = () => {
        return Boolean(user.token);

    }
    const isAuthorised = () => {
        if(typeof groups_permitted === "undefined" || groups_permitted.length < 1) return true;
        let valid = false;
        if(typeof user.user?.groups === 'undefined') return false
        user.user?.groups.map(group => {
            if(groups_permitted.indexOf(group.name) > -1) {
                valid = true;
                return;
            }
        })
        return valid;
    }

    if(canViewRoute() && isAuthorised()) {
        return <Route {...routeProps}/>;
    } else if(canViewRoute()) {
        return <Redirect to={{pathname: 'permission_denied'}}/>;
    }
    else {
        return <Redirect to={{pathname: authentication_path}}/>;
    }
}