import * as React from 'react';
import {useDateSelector} from "../../../../states/components/useDateSelector";
import {Form, FormGroup, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {DateTime} from "luxon";
import {useEffect} from "react";

export const DateSelector = ({onChangeFunc, givenDate}:{onChangeFunc:(date:DateTime)=>void, givenDate?:DateTime}) => {
    const {
        date,
        changeYear,
        changeMonth,
        changeDay,
        daysInMonth
    } = useDateSelector(onChangeFunc, givenDate)

    return (
        <Form inline>
            <FormGroup style={{width:"25%"}}>
                <Input bsSize={"sm"} type={"text"} onChange={changeYear} value={date.year} placeholder={"Year"} className={"rounded-0 w-100"}/>
            </FormGroup>
            <FormGroup style={{width:"45%"}}>
                <Input bsSize={"sm"} type={"select"} value={date.month} onChange={changeMonth} className={"rounded-0 w-100"}>
                    <option value={0}>Month...</option>
                    <option value={1}>January</option>
                    <option value={2}>February</option>
                    <option value={3}>March</option>
                    <option value={4}>April</option>
                    <option value={5}>May</option>
                    <option value={6}>June</option>
                    <option value={7}>July</option>
                    <option value={8}>August</option>
                    <option value={9}>September</option>
                    <option value={10}>October</option>
                    <option value={11}>November</option>
                    <option value={12}>December</option>
                </Input>
            </FormGroup>
            <FormGroup style={{width:"30%"}}>
                <Input bsSize={"sm"} type={"select"} onChange={changeDay} value={date.day} className={"rounded-0 w-100"}>
                    {daysInMonth.length < 1?
                        <option>Day...</option>
                        :
                        (daysInMonth.map(day => {
                            return <option key={day}>{day}</option>
                        }))}
                </Input>
            </FormGroup>
        </Form>
    )
}