import * as React from 'react';
import {FullWidthTemplate} from "../../components/templates/FullWidthTemplate";
import {ServingMembersList} from "../../components/serving_members/molecules/ServingMembersList";
import {PageQuote} from "../../components/serving_members/atoms/PageQuote";
import {Col} from "reactstrap";
import {QuarterSplitTemplate} from "../../components/templates/QuarterSplitTemplate";
import {ProfileTabPane} from "../../components/serving_members/molecules/ProfileTabPane";
import {useServingMembersByLodge} from "../../states/serving_members/useServingMembersByLodge";
import {useTimeline} from "../../states/timeline/useTimeline";

export const ServingMembersPage = (props:any) => {
    const {profiles, activeTab, changeActiveTab} = useServingMembersByLodge(props.match.params.slug)

    return (
        <FullWidthTemplate content={
            <>
                <PageQuote/>
                <QuarterSplitTemplate minorContent={
                    <ServingMembersList changeTab={changeActiveTab} profiles={profiles} />
                } majorContent={
                    <ProfileTabPane profiles={profiles} activeTab={activeTab}/>
                }/>
            </>
        }/>
    )
}