import * as React from 'react';
import {Button} from "reactstrap";
import {IMeetingHall} from "../../../interfaces/MeetingHall";
import {IRegularMeeting} from "../../../interfaces/RegularMeeting";

type MyProps = {
    regular_meeting: IRegularMeeting
}

export const LodgeMeetingText = (props:MyProps) => {
    return (
        <p>Meet at <Button color={"link align-baseline"} href={props.regular_meeting.meeting_hall.link} className={"p-0"}>{props.regular_meeting.meeting_hall.name}</Button> on the {props.regular_meeting.nth_day} {props.regular_meeting.weekday} of the month.
            Except for {props.regular_meeting.exception_months.map((month, ind) => {
                if(ind > 0) {
                    return <a key={ind}>, {month}</a>
                }
                return <a key={ind}> {month}</a>
            })}.</p>
    )
}