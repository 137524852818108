import {ILodge} from "../../interfaces/Lodge";
import {useEffect, useState} from "react";

export const useLodgeSelector = (givenLodges:ILodge[], multiple:boolean=false, onChangeFunc?:(lodge:ILodge[])=>void) => {
    const [lodges, setLodges] = useState(givenLodges);
    const [selectedLodges, setSelectedLodges] = useState<ILodge[]>([])

    const changeSelectedLodge = (e:any) => {
        const selectedOptions = e.target.selectedOptions;
        const values = Array.from(selectedOptions, (i:any) => i.value);
        const lodgesArray:ILodge[] = [];
        values.map(value => {
            lodges.map(lodge => {
                if(lodge.slug === value) {
                    lodgesArray.push(lodge)
                }
            })
        })
        setSelectedLodges(lodgesArray);
    }

    useEffect(()=>{
        if(!selectedLodges) return
        if(onChangeFunc) {
            onChangeFunc(selectedLodges)
        }
    },[selectedLodges])

    useEffect(()=>{setLodges(givenLodges)},[givenLodges])

    return {
        lodges,
        selectedLodges,
        changeSelectedLodge
    }
}