import * as React from 'react';
import {useMasonicProfileFormOld} from "../../../states/masonic_profile/useMasonicProfileForm.old";
import {IMasonicProfile, IUser} from "../../../interfaces/User";
import {Form, FormGroup, Input, Label} from "reactstrap";
import {DateSelector} from "../../misc/forms/organisms/DateSelector";
import {DateTime} from "luxon";
import {useLodges} from "../../../states/lodge/useLodges";
import {LodgeSelector} from "../../lodge/organisms/LodgeSelector";

export const MasonsProfileForm = ({props, updatePreview}:{props:any, updatePreview?:(profile:IMasonicProfile)=>void}) => {
    const {
        rank,
        initiation,
        passing,
        raising,
        occupation,
        motherLodge,
        memberOf,
        verified,
        rankChange,
        joinDateChange,
        verifiedChange,
        changePassing,
        changeRaising,
        changeOccupation,
        changeMotherLodge,
        changeMemberOfLodges
    } = useMasonicProfileFormOld({props, updatePreview});

    const {
        lodges
    } = useLodges();

    return (
        <Form>
            <FormGroup>
                <Label>Initiation</Label>
                {initiation?
                    <DateSelector onChangeFunc={joinDateChange} givenDate={DateTime.fromFormat(initiation?initiation:'', 'dd/MM/yyyy')}/>
                    :
                    <DateSelector onChangeFunc={joinDateChange}/>
                }
            </FormGroup>
            <FormGroup>
                <Label>Passing</Label>
                {passing?
                    <DateSelector onChangeFunc={changePassing} givenDate={DateTime.fromFormat(passing?passing:'', 'dd/MM/yyyy')}/>
                    :
                    <DateSelector onChangeFunc={changePassing}/>
                }
            </FormGroup>
            <FormGroup>
                <Label>Raising</Label>
                {raising?
                    <DateSelector onChangeFunc={changeRaising} givenDate={DateTime.fromFormat(raising?raising:'', 'dd/MM/yyyy')}/>
                    :
                    <DateSelector onChangeFunc={changeRaising}/>
                }
            </FormGroup>
            <FormGroup>
                <Label for={"occupation"}>Occupation</Label>
                <Input type={"text"} name={"occupation"} onChange={changeOccupation} value={occupation}/>
            </FormGroup>
            <FormGroup>
                <Label for={"mother_lodge"}>Mother Lodge</Label>
                <LodgeSelector givenLodges={lodges} multiple={false} onChangeFunc={changeMotherLodge}/>
            </FormGroup>
            <FormGroup>
                <Label for={"member_of"}>Member of...</Label>
                <LodgeSelector givenLodges={lodges} multiple={true} onChangeFunc={changeMemberOfLodges}/>
            </FormGroup>
        </Form>
    )
}