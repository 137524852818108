import * as React from 'react';
import {useDropdown} from "../../../states/components/useDropdown";
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import { BsPersonFill } from "react-icons/all";
import {LogoutButton} from "../atoms/LogOutButton";

export const ProfileQuickMenu = () => {
    const { isOpen, toggle } = useDropdown();

    return (
        <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggle>
                <h4><BsPersonFill className={"align-middle"}/></h4>
            </DropdownToggle>
            <DropdownMenu right={true}>
                {/*<DropdownItem className={"ml-0"}>My Account</DropdownItem>*/}
                <DropdownItem href={'/my/profile'} className={"ml-0"}>My Profile</DropdownItem>
                <DropdownItem divider/>
                <DropdownItem className={"ml-0"}><LogoutButton/></DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}