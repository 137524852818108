import {IMasonicProfile} from "../../../interfaces/User";
import {MasonsRankProgressTable} from "../molecules/MasonsRankProgressTable";
import {MotherLodgeDisplay} from "../atoms/MotherLodgeDisplay";
import {MemberOfDisplay} from "../atoms/MemberOfDisplay";
import {ListGroup, ListGroupItem} from "reactstrap";

export const MasonsProfile = ({profile}:{profile:IMasonicProfile}) => {
    return (
        <>
            <h2>Preview:</h2>
            <MasonsRankProgressTable initiation={profile.initiation} passing={profile.passing} raising={profile.raising}/>
            {profile.mother_lodge && <MotherLodgeDisplay lodge={profile.mother_lodge} />}
            <ListGroup>
                {profile.lodges && profile.lodges.map(lodge => {
                    return <ListGroupItem><MemberOfDisplay lodge={lodge}/></ListGroupItem>
                })}
            </ListGroup>
        </>
    )
}