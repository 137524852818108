import {IArtist} from "../../../interfaces/User";
import {useArtistForm} from "../../../states/artist/useArtistForm";
import {Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {AddressForm} from "../../address/forms/address.form";
import {DefaultAddress} from "../../../defaults/address.default";

export const ArtistForm = ({givenArtist}:{givenArtist:IArtist}) => {
    const {
        thisArtist,
        updateValue,
        errors
    } = useArtistForm({givenArtist:givenArtist});

    return (
        <>
            <Form>
                <FormGroup>
                    <Label>Artist Name*</Label>
                    <Input type={"text"} value={thisArtist.name} placeholder={"Art Tiste"} onChange={updateValue} name={"artist_name"} invalid={errors.hasOwnProperty('artist_name')}/>
                    <FormFeedback>{errors.hasOwnProperty('artist_name') && errors.artist_name}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Artist Website</Label>
                    <Input type={"text"} value={thisArtist.website} placeholder={"artist.co.uk"} onChange={updateValue} name={"artist_website"}/>
                </FormGroup>
            </Form>
            <h3>Artist's address</h3>
            <AddressForm givenAddress={thisArtist.address || DefaultAddress}/>
        </>
    )
}