import * as React from 'react';
import {ListGroup, ListGroupItem} from "reactstrap";

type MyProps = {
    consecration_year: string,
    location_name: string
}

export const LodgeMinorTable = (props:MyProps) => {
    const date = new Date(props.consecration_year);

    return (
        <ListGroup horizontal>
            <ListGroupItem>Consecrated: {date.getFullYear()}</ListGroupItem>
            <ListGroupItem>Location: {props.location_name}</ListGroupItem>
        </ListGroup>
    )
}