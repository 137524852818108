import * as React from 'react';
import {useProfileForm} from "../../../states/profile/useProfileForm";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {IUser} from "../../../interfaces/User";
import {DateSelector} from "../../misc/forms/organisms/DateSelector";
import {DateTime} from "luxon";

type MyProps = {
    edit_dod: boolean,
    user?: IUser,
    self?: boolean
}

const defaultProps:MyProps = {
    edit_dod: false,
    self: true
}

const ProfileForm = (props:MyProps) => {
    const {
        first_name,
        last_name,
        date_of_birth,
        date_of_death,
        contact_number,
        loading,
        submit,
        firstNameChange,
        lastNameChange,
        dobChange,
        dodChange,
        numberChange
    } = useProfileForm(props.user?.profile, props.self);

    return (
        <Form>
            <Row form>
                <Col>
                    <FormGroup>
                        <Label for={"first_name"}>First Name</Label>
                        <Input type={"text"} name={"first_name"} onChange={firstNameChange} value={first_name} required={true}/>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label for={"last_name"}>Last Name</Label>
                        <Input type={"text"} name={"last_name"} onChange={lastNameChange} value={last_name} required={true}/>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label for={"contact_number"}>Contact Number</Label>
                <Input type={"text"} name={"contact_number"} onChange={numberChange} value={contact_number}/>
            </FormGroup>
            <FormGroup>
                <Label for={"date_of_birth"}>Date of birth</Label>
                {typeof date_of_birth !== "undefined"?
                    <DateSelector onChangeFunc={dobChange} givenDate={DateTime.fromISO(date_of_birth)}/>
                    :
                    <DateSelector onChangeFunc={dobChange}/>
                }
            </FormGroup>
            {props.edit_dod?
                (typeof date_of_death !== "undefined")?
                        <FormGroup><Label>Date of Death</Label><DateSelector onChangeFunc={dodChange}/></FormGroup>
                        :
                        ''
                 :
                '' }
            <Button onClick={submit} color={"primary"} block disabled={loading}>Save</Button>
        </Form>
    )
}

ProfileForm.defaultProps = defaultProps;

export default ProfileForm;