import {IMasonicProfile} from "../../interfaces/User";
import {SyntheticEvent, useEffect, useState} from "react";
import {IValidationResult} from "../../interfaces/Validation";
import {usePortraitForm} from "../portrait/usePortraitForm";
import {DefaultMasonicProfile} from "../../defaults/profile.default";
import {DefaultPortrait} from "../../defaults/portrait.default";
import {DateTime} from "luxon";
import {useCalendarEvents} from "../calendar/useCalendarEvents";

export const useMasonicProfileForm = ({givenProfile}:{givenProfile:IMasonicProfile}) => {
    const [thisProfile, setThisProfile] = useState<IMasonicProfile>(givenProfile);
    const [errors, setErrors] = useState<IValidationResult>({});

    const {
        updateValue:updatePortraitValue,
        errors:portraitErrors,
        thisPortrait,
        validation: portraitValidation
    } = usePortraitForm({givenPortrait:givenProfile.portrait||DefaultPortrait});

    const updateValue = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement, name = target.name, value = target.value;
        switch (name) {
            case "rank":
                setThisProfile(thisProfile => ({...thisProfile, rank: value}));
                break;
        }
    }

    const updateInitiation = (date:DateTime) => {
        setThisProfile(thisProfile => ({...thisProfile, initiation: date.toLocaleString()}))
    }
    const updatePassing = (date:DateTime) => {
        setThisProfile(thisProfile => ({...thisProfile, passing: date.toLocaleString()}))
    }
    const updateRaising = (date:DateTime) => {
        setThisProfile(thisProfile => ({...thisProfile, raising: date.toLocaleString()}))
    }

    const validation = () => {
        if(thisProfile.rank.trim().length < 1) {
            setErrors(errors => ({...errors, ["rank"]:["This field is required"]}))
        }
    }

    useEffect(() => {
        setErrors(errors => ({...errors, ...portraitErrors}));
    }, [portraitErrors])
    useEffect(()=>{
        setThisProfile(thisProfile => ({...thisProfile, portrait: thisPortrait}))
    },[thisPortrait])

    return {
        thisProfile,
        errors,
        updateValue,
        updateInitiation,
        updatePassing,
        updateRaising
    }
}