import * as React from 'react';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {useNavBar} from "../../../states/components/useNavBar";
import {NavCollapseMenu} from "../../misc/forms/molecules/NavCollapseMenu";
import {BsChevronRight} from "react-icons/all";

export const DashboardHeader = () => {
    const {isOpen, toggle} = useNavBar();

    return (
        <>
            <Navbar color={"fmis-primary"} dark expand={"md"} vertical className={"d-flex flex-column h-100 px-0"}>
                <NavbarBrand href={"/"} className={""}>Freemasonry in Sheffield <small className={"d-block text-center"}>Dashboard</small></NavbarBrand>
                <NavbarToggler onClick={toggle}/>
                <Collapse isOpen={isOpen} className={"col-12 text-right"} navbar>
                    <Nav className={"col-12 px-0"} vertical>
                        <NavItem>
                            <NavCollapseMenu title={'Research'} links={[
                                {title:'Create new timeline event', url:'/timeline/create'},
                                {title:'Create portrait', url:'/research/portrait/create'},
                                {title:'View Timeline Tags', url:'/timeline/tags'}
                                ]}/>
                        </NavItem>
                        <NavItem>
                            <NavCollapseMenu title={'My Account'} links={[
                                {title:'My Profile', url:'/my/profile'},
                                {title:'My Freemasons Profile', url:'/my/masons_profile'}
                            ]}/>
                        </NavItem>
                        <NavItem>
                            <NavCollapseMenu title={'Accounts'} links={[
                                {title: 'Register a new user', url: '/account/registration'}
                            ]
                            }/>
                        </NavItem>
                        {/*<NavItem>*/}
                        {/*    <NavLink className={"text-white"} href={"/profile"}>Profile <BsChevronRight className={"align-middle"}/></NavLink>*/}
                        {/*</NavItem>*/}
                    </Nav>
                </Collapse>
            </Navbar>
        </>
    )
}