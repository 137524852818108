import * as React from 'react';
import {useLodges} from "../../states/lodge/useLodges";
import {FullWidthTemplate} from "../../components/templates/FullWidthTemplate";
import {LodgeList} from "../../components/lodge/organisms/LodgeList";
import {QuarterSplitTemplate} from "../../components/templates/QuarterSplitTemplate";

export const LodgeListPage = () => {
    return (
        <FullWidthTemplate content={
            <QuarterSplitTemplate
                minorContent={
                    ""
                }
                majorContent={
                    <LodgeList/>
                }
                title={"Lodges"}
            />
        }/>
    )
}