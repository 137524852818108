import {IMasonicProfile, IProfile} from "../interfaces/User";
import {DefaultPortrait} from "./portrait.default";
import {DateTime} from "luxon";

export const DefaultProfile:IProfile = {
    first_name: "", last_name: ""
}

export const DefaultMasonicProfile:IMasonicProfile = {
    initiation: DateTime.now().toISODate(), rank: "",portrait:DefaultPortrait
}