import * as React from 'react';
import {GradientLink} from "./GradientLink";

type myProps = {
    image: string,
    href?: string,
    text: string,
    className: string
}

export const GradientImageLink = (props:myProps) => {
    return (
        <div
            className={props.className}
            style={{
                backgroundImage:`url(${props.image}`,
                backgroundPosition:"center",
                backgroundSize:"cover",
                backgroundRepeat:"no-repeat"
            }}>
            <GradientLink text={props.text} href={props.href}/>
        </div>
    )
}