import * as React from 'react';
import {FullWidthTemplate} from "../../components/templates/FullWidthTemplate";
import {Timeline} from "../../components/timeline/organisms/Timeline";

export const TimelinePage = (props:any) => {
    return (
        <FullWidthTemplate content={
            <Timeline slug={props.match.params.slug ? props.match.params.slug : 'a_brief_history'}/>
        }/>
    )
}