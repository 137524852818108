import {IPortrait} from "../../interfaces/User";
import {SyntheticEvent, useEffect, useState} from "react";
import {IValidationResult} from "../../interfaces/Validation";
import {useArtistForm} from "../artist/useArtistForm";
import {DefaultArtist} from "../../defaults/artist.default";
import {DateTime} from "luxon";

type myType = {
    givenPortrait: IPortrait
}

export const usePortraitForm = ({givenPortrait}:myType) => {
    const [thisPortrait, setThisPortrait] = useState<IPortrait>(givenPortrait);
    const [errors, setErrors] = useState<IValidationResult>({});
    const [upload, setUpload] = useState<File>();

    const {
        thisArtist,
        updateValue:updateArtistValue,
        updateAddressValue,
        validation:artistValidation,
        errors:artistErrors
    } = useArtistForm({givenArtist:givenPortrait.artist||DefaultArtist});

    const updateValue = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement, name = target.name, value = target.value;
        switch (name) {
            case "image":
                if(target.files && target.files[0]) {
                    const file = target.files[0];
                    setUpload(file);
                    setThisPortrait(thisPortrait => ({...thisPortrait, image:file.name}));
                }
                break;
            case "financed_by":
                setThisPortrait(thisPortrait => ({...thisPortrait, financed:value}));
                break;
            case "presented_by":
                setThisPortrait(thisPortrait => ({...thisPortrait, presented:value}));
                break;
        }
    }
    const updatePresentedDate = (date:DateTime) => {
        setThisPortrait(thisPortrait => ({...thisPortrait, presented:date.toLocaleString()}))
    }
    const validation = () => {
        if(typeof upload === "undefined") {
            setErrors(errors => ({...errors, ["image"]:["You need to add an image"]}));
        }
        artistValidation();
    }

    useEffect(()=>{
        setErrors(errors => ({...errors, ...artistErrors}));
    },[artistErrors])
    useEffect(()=>{
        setThisPortrait(thisPortrait => ({...thisPortrait, artist:thisArtist}))
    }, [thisArtist])

    return {
        updateArtistValue,
        updateAddressValue,
        updateValue,
        updatePresentedDate,
        thisPortrait,
        errors,
        validation
    }
}