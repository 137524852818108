import * as React from 'react';
import {ILodge} from "../../../interfaces/Lodge";
import {LodgeProfileMetaTitle} from "../atoms/LodgeProfileMetaTitle";
import {LodgeMeetingText} from "../atoms/LodgeMeetingText";

export const LodgeProfile = (props:ILodge) => {
    return (
        <>
            <h1 className={"text-responsive"}>{props.name} <strong>No. {props.number}</strong></h1>
            <LodgeProfileMetaTitle consecrated_year={props.consecrated}/>
            <LodgeMeetingText regular_meeting={props.regular_meeting}/>
        </>
    )
}