import * as React from 'react';
import {DashboardPage} from "../pages/dashboard/DashboardPage";
import {PermissionDeniedPage} from "../pages/dashboard/PermissionDeniedPage";

export const dashboardRoutes = [
    {
        path: '/dashboard',
        component: DashboardPage,
        isPrivate: true,
        exact: true
    },
    {
        path: '/permission_denied',
        component: PermissionDeniedPage,
        isPrivate: true
    }
]