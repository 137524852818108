import * as React from 'react';
import {useLodge} from "../../states/lodge/useLodge";
import {LodgeProfile} from "../../components/lodge/organisms/LodgeProfile";
import {Timeline} from "../../components/timeline/organisms/Timeline";
import {Header} from "../../components/layout/Header";
import {Footer} from "../../components/layout/Footer";
import {NotFoundPage} from "../common/404.page";
import {LoadingOrganism} from "../../components/misc/status/Loading.organism";
import {LoadingPage} from "../common/Loading.page";

export const LodgeProfilePage = (props:any) => {
    const {lodge, loading} = useLodge({slug:props.match.params.slug});

    if(loading) {
        return <LoadingPage/>
    }

    if(lodge === undefined) {
        return (
            <NotFoundPage/>
        )
    }

    return (
        <>
            <Header/>
            <div className={"col-8 mx-auto"}>
                <LodgeProfile {...lodge}/>
            </div>

            <Timeline slug={lodge.slug}/>
            <Footer/>
        </>
    )
}