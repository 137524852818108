import {FullWidthTemplate} from "../../components/templates/FullWidthTemplate";
import {ContactForm} from "../../components/misc/forms/ContactForm";

const LibraryPage = () => {
    const content = (
        <>
            <div className={"col-8 mx-auto"}>
                <h1>Library</h1>
                <div className={"p-2"}>
                    <p>
                        <i>The Hallamshire College Library and Museum located in Tapton Hall is a hub of masonic history,
                            learning, and curiosity.</i>
                    </p>
                    <p>
                        Founded in 1876, the library was tasked with collecting a wide range of masonic literature and
                        artefacts relating to Freemasonry locally and globally. This tradition has continued to this very day
                        and the library boasts a collection of well over 6000 books with some dating back to the 16th
                        Century. The library’s book collection ranges from general masonic chronologies to multitudes of
                        writings on specific jurisdictions and orders including masonry’s impact on global political and
                        societal history throughout the ages.
                    </p>
                    <p>Alongside this, the library is lucky to have many artefacts in its collection. From rare masonic jewels
                        to regalia pre-dating The United Grand Lodge of England, we are proud to maintain an insight into
                        the vibrant history of the world’s most famous brotherhood. Our artefacts also reflect the impact
                        Freemasonry has had on culture and society throughout the centuries by maintaining items of a
                        masonic theme ranging from shaving implements to decanters.</p>
                    <p>We are at hand to cater for the general enthusiast as well as the amateur or professional researcher.
                        We stand as a testament to ‘daily advancement’ for any mason wanting to explore their
                        membership further whatever the topic may be. The library has also supported several academic
                        research projects over the decades including the world-renowned Sheffield Centre for Research into
                        Freemasonry and Fraternalism not to mention a variety of MA and PhD theses. Our in-house experts
                        at the library will be happy to help with any questions you have.</p>
                    <p>If you would like to visit the library out of curiosity or for research purposes, we would be delighted
                        to hear from you below. Workspaces and the use of computers are available.</p>
                    <ContactForm/>
                </div>
            </div>
        </>
    )

    return (
        <FullWidthTemplate content={content}/>
    )

}

export default LibraryPage;