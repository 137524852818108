import * as React from 'react';
import Api from "../../config/Api";
import {authActionTypes} from "../../reducers/AuthReducer";

export async function loginUser(dispatch:React.Dispatch<authActionTypes>, loginPayload:{username:string, password:string}) {
    try {
        const loginData = {
            "user": {
                "email": loginPayload.username,
                "password": loginPayload.password
            }
        }
        dispatch({type: 'REQUEST_LOGIN', payload:null});

        return await Api.post('account/login', loginData)
            .then(
                response => {
                    if(response.data.user) {
                        const formattedData = {
                            user: response.data.user,
                            token: response.data.token
                        }
                        localStorage.setItem('currentUser', JSON.stringify(formattedData.user));
                        localStorage.setItem('token', formattedData.token);
                        dispatch({type:'LOGIN_SUCCESS', payload: formattedData});
                        return formattedData;
                    }
                }
            ).catch(
                err => {
                    dispatch({type:'LOGIN_ERROR', payload:'Login error, please check your username and password.'})
                    return err
                }
            );
    } catch (error) {
        dispatch({type:'LOGIN_ERROR', payload:'Please check your username and password'})
    }
}

export async function logout(dispatch:React.Dispatch<authActionTypes>) {
    dispatch({type:'LOGOUT', payload:null});
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
}

export async function updateCurrent(dispatch:React.Dispatch<authActionTypes>, token?:string) {
    try {
        let response = await Api
            .get('account/current', {headers: {'Authorization': `Token ${token}`}});
        let data = await response.data;
        console.log('updated current user')
        if(data) {
            localStorage.setItem('currentUser', data)
        }
    } catch (error) {

    }
}