import {useTimelineTags} from "../../../states/timeline/useTimelineTags";
import {Button, Row, Table} from "reactstrap";
import {FaSortAlphaDown, FaSortAlphaUp} from "react-icons/all";

export const TimelineTagTable = () => {
    const {
        tags,
        getTags,
        sortByName,
        sortBySlug,
        nameAsc,
        slugAsc
    } = useTimelineTags();

    return (
        <Table striped size={"sm"}>
            <thead>
            <tr>
                <td onClick={sortByName}>Name<a className={"float-right"}>{nameAsc?<FaSortAlphaUp/>:<FaSortAlphaDown/>}</a></td>
                <td onClick={sortBySlug}>Slug<a className={"float-right"}>{slugAsc?<FaSortAlphaUp/>:<FaSortAlphaDown/>}</a></td></tr>
            </thead>
            <tbody>
            {tags.map(tag => {
                return <tr key={tag.slug}><td>{tag.name}</td><td>{tag.slug}</td></tr>
            })}
            </tbody>
        </Table>
    )
}