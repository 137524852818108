import * as React from 'react';
import {logout} from '../../contexts/auth/actions';
import {useAuthDispatch} from "../../contexts/auth/authContext";
import {useHistory} from "react-router-dom";

export const useLogout = () => {
    const dispatch = useAuthDispatch();
    const history = useHistory();
    const handleLogout = async(event:any) => {
        await logout(dispatch);
        return history.push('/dashboard');
    }
    return {handleLogout}
}