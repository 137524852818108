import * as React from 'react';
import {ILodgeList} from "../../../interfaces/Lodge";
import {ILodgesState, useLodges} from "../../../states/lodge/useLodges";
import {LodgeListItem} from "../molecules/LodgeListItem";
import {Spinner} from "reactstrap";

export const LodgeList = () => {
    const {lodges, loading} = useLodges();

    if(loading) {
        return (
            <>
                <Spinner/>
            </>
        )
    }

    if(lodges.length < 1) {
        return (
            <>
                <h1>No lodges found.</h1>
            </>
        )
    }

    return (
        <>
            {lodges.map(lodge => {
                return (
                    <LodgeListItem lodge={lodge}/>
                )
            })}
        </>
    )
}