import * as React from 'react';
import {ITimeline, ITimelineEvent, ITimelineTag} from "../../../interfaces/Timeline";
import {TimelineYear} from "../atoms/TimelineYear";
import {TimelineDescription} from "../atoms/TimelineDescription";
import {TimelineTagLink} from "../atoms/TimelineTagLink";
import {TimelineTagModal} from "../atoms/TimelineTagModal";
import Moment from "react-moment";
import {Spinner} from "reactstrap";
import {TimelineEvent} from "./TimelineEvent";

type MyProps = {
    events: ITimelineEvent[],
    loading: boolean
};

const defaultProps = {
    events: [],
    loading: true
}

export const TimelineEvents = (props:MyProps) => {
    if(props.loading) {
        return <div><Spinner/></div>
    }

    if ( props.events.length === 0 ) {
        return <div>Sorry, No events found for this timeline</div>
    }

    return (
        <>
            {
                props.events.map(
                    event => <TimelineEvent key={event._id} event={event}/>
                    )
            }
        </>
    )
}

TimelineEvents.defaultProps = defaultProps;