import {useAuthState} from "../../contexts/auth/authContext";
import {UserDashboardTemplate} from "../../components/templates/UserDashboardTemplate";
import {ProfileSearch} from "../../components/profile/molecules/ProfileSearch";
import ProfileForm from "../../components/user/molecules/ProfileForm";
import {Col, Container, Row} from "reactstrap";
import {PortraitForm} from "../../components/portrait/forms/portrait.form";
import {AddressForm} from "../../components/address/forms/address.form";
import {DefaultAddress} from "../../defaults/address.default";
import {DefaultPortrait} from "../../defaults/portrait.default";
import {MasonicProfileForm} from "../../components/masonic_profile/forms/masonicProfile.form";
import {DefaultMasonicProfile} from "../../defaults/profile.default";

export const PortraitCreationPage = () => {
    const {user} = useAuthState();

    const content = (
        <>
            {/*<PortraitForm/>*/}
            <MasonicProfileForm givenProfile={DefaultMasonicProfile}/>
        </>
    )

    return (
        <UserDashboardTemplate content={content}/>
    )
}