import * as React from 'react';
import {useState} from "react";
import Api from "../config/Api";

export const useContactForm = () => {
    const [contact_name, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [emailTwo, setEmailTwo] = useState('');

    type FormErrorsType = {
        contact_name?:string
        email?:string
        phone_number?:string
        message?:string
    }
    const [formErrors, setFormErrors] = useState<FormErrorsType>({});
    const [formMessage, setFormMessage] = useState({
        color:'secondary',
        message: ''
    });

    const [loading, setLoading] = useState(false);

    const changeContactName = (e:any) => {
        setContactName(e.target.value);
    }

    const changeEmail = (e:any) => {
        setEmail(e.target.value);
    }

    const changeEmailTwo = (e:any) => {
        setEmailTwo(e.target.value);
    }

    const changePhoneNumber = (e:any) => {
        setPhoneNumber(e.target.value);
    }

    const changeMessage = (e:any) => {
        setMessage(e.target.value);
    }

    const validation = () => {
        let formValid = true;
        let errors:{[key:string]:string} = {};
        if(emailTwo.length > 0) {
            formValid = false;
        }
        //contact name
        if(!(contact_name.length > 0)) {
            errors["contact_name"] = "This field is required";
            formValid = false;
        }
        //email
        const regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        if(!(email.length > 0) || !regex.test(email)) {
            errors["email"] = "Please enter a valid email address";
            formValid = false;
        }
        //phone number
        if(phone_number.toString().trim().length > 0 && isNaN(+phone_number)) {
            errors["phone_number"] = "Please enter a valid phone number";
            formValid = false;
        }
        //message
        if(message.toString().trim().length < 3) {
            errors["message"] = "Please enter a message";
            formValid = false;
        }
        if(!formValid) {
            setFormErrors(errors);
            return false;
        }
        setFormErrors({});
        return true;
    }
    const formReset = () => {
        setContactName('');
        setEmail('');
        setPhoneNumber('');
        setMessage('');
        setFormErrors({});
        setFormMessage({color:'secondary', message: ''});
    }
    const submit = () => {
        if(!validation()) {
            return;
        }
        setLoading(true);
        Api
            .put('enquiry', {
                contact_name: contact_name,
                email: email,
                phone_number: phone_number,
                message: message
            })
            .then(
                res => {
                    setLoading(false);
                    setFormMessage({
                        color: 'success',
                        message: 'Your message was sent, thank you'
                    });
                    formReset();
                }
            )
            .catch(
                err => {
                    setLoading(false);
                    setFormMessage({
                        color: 'danger',
                        message: err
                    })
                }
            )
    }

    return {
        contact_name, changeContactName,
        email, changeEmail,
        emailTwo, changeEmailTwo,
        phone_number, changePhoneNumber,
        message, changeMessage,
        formMessage,
        formErrors,
        loading,
        submit
    }
}