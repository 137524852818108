import * as React from 'react';
import {useDashboard} from "../../../states/useDashboard";
import {IUser} from "../../../interfaces/User";

export const DashboardGreeting = ({user}: {user:IUser}) => {
    return (
        <>
            <h2>Hello {(user?.profile?.first_name)?(user?.profile?.first_name):(user.account.email)},</h2>
            <h3>Welcome to the dashboard, this site is still in the early stages of development. If you have any suggestions or feedback please get in touch.</h3>
        </>
    )
}