import * as React from 'react';
import {useDropdown} from "../../../../states/components/useDropdown";
import {Button, Collapse, Dropdown, NavItem, NavLink} from "reactstrap";
import {BsChevronDown, BsChevronRight} from "react-icons/all";

type myProps = {
    title: string
    links: {
        title: string
        url: string
    }[]
}

export const NavCollapseMenu = (props:myProps) => {
    const {isOpen, toggle} = useDropdown();

    return (
        <>
            <NavLink onClick={toggle} className={"text-white"}>{props.title} {isOpen?<BsChevronDown className={"align-middle"}/>:<BsChevronRight className={"align-middle"}/>}</NavLink>
            <Collapse className={"bg-secondary"} isOpen={isOpen}>
                {props.links.map((link, id) => {
                    return <NavLink key={id} className={"text-white"} href={link.url}>{link.title}</NavLink>
                })}
            </Collapse>
        </>
    )
}