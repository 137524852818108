import * as React from 'react';
import {FullWidthTemplate} from "../../components/templates/FullWidthTemplate";
import {LoginForm} from "../../components/user/molecules/LoginForm";
import {useAuthState} from "../../contexts/auth/authContext";
import {useHistory} from "react-router-dom";

export const LoginPage = () => {
    const {user} = useAuthState();
    const history = useHistory();
    // if(user !== undefined) {
    //     history.push('/dashboard');
    // }
    return (
        <FullWidthTemplate content={
            <LoginForm/>
        } className={"col-8 mx-auto"}/>
    )
}