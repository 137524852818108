import * as React from 'react';
import {NavItem, NavLink} from "reactstrap";

export const ServingMemberListItem = (
    {rank, first_name, last_name, slug, setActiveProfile, isActive}:
        {rank:string|undefined, first_name:string, last_name:string, slug:string|undefined, setActiveProfile:(slug:string)=>void, isActive:boolean}) => {

    return (
        <NavItem>
            <NavLink
                active={isActive}
                className={'text-white text-right'}
                onClick={()=>{
                    if(!slug) return
                    setActiveProfile(slug)
                }}
            >{rank} {first_name} {last_name}</NavLink>
        </NavItem>
    )
}