import {useState} from "react";
import {IMasonicProfile} from "../../interfaces/User";

export const useMasonsProfileEditPage = () => {
    const now = Date.now().toString();
    const [profilePreview, setProfilePreview] = useState<IMasonicProfile>(
        {
            initiation: now,
            passing: now,
            raising: now,
            occupation: 'Not defined',
            rank: 'Not defined'
        }
    );

    const changeProfilePreview = (profile:IMasonicProfile) => {
        setProfilePreview(profile);
    }

    return {
        profilePreview,
        changeProfilePreview
    }
}