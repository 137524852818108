import * as React from 'react';
import {useState} from "react";
import {ITimelineEvent} from "../../interfaces/Timeline";
import {DateTime} from "luxon";

export const useTimelineEditPage = () => {
    const [eventPreview, setEventPreview] = useState<ITimelineEvent>({
        _id: '',
        tags: [],
        description: 'This is an example timeline event',
        date: DateTime.now(),
        show_day: true,
        show_month: true
    });

    const changeEventPreview = (event:ITimelineEvent) => {
        setEventPreview(event);
    }

    const [savedEvents, setSavedEvents] = useState<ITimelineEvent[]>([])
    const changeSavedEvents = (event:ITimelineEvent) => {
        savedEvents.push(event);
        setSavedEvents(savedEvents);
    }

    return {eventPreview, changeEventPreview, changeSavedEvents, savedEvents}
}