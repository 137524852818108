import * as React from 'react';
import {Button} from "reactstrap";
import {LodgeNumberBadge} from "../atoms/LodgeNumberBadge";

type MyProps = {
    name: string,
    location: string,
    number: number,
    size?: string,
    isResponsive?: boolean
}

export const LodgeLink = (props:MyProps) => {
    const additionalClass = (typeof props.isResponsive === "undefined" || props.isResponsive)?" text-responsive ":"";
    return <Button color={"link"} href={props.location} className={"text-reset"+additionalClass} size={props.size?props.size:'lg'}>{props.name} <LodgeNumberBadge number={props.number}/></Button>
}