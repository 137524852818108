import * as React from 'react';
import {QuarterSplitTemplate} from "./QuarterSplitTemplate";
import {DashboardHeader} from "../dashboard/molecules/DashboardHeader";
import {TopBarTemplate} from "./TopBarTemplate";
import {DashboardNotificationBar} from "../dashboard/molecules/DashboardNotificationBar";

type myProps = {
    content: any
}

export const UserDashboardTemplate = (props:myProps) => {
    return (
        <>
            <QuarterSplitTemplate
                minorContent={<DashboardHeader/>}
                majorContent={
                    <TopBarTemplate
                        barContent={<DashboardNotificationBar/>}
                        pageContent={props.content}
                    />}
            />
        </>
    )
}
