import {useEffect, useState} from "react";
import {ITimelineTag} from "../../interfaces/Timeline";
import Api from "../../config/Api";
import {PaginationHelper} from "../../helpers/pagination.helper";

export const useTimelineTags = () => {
    const [tags, setTags] = useState<ITimelineTag[]>([]);
    const [nameAsc, setNameAsc] = useState<boolean>(true);
    const [slugAsc, setSlugAsc] = useState<boolean>(true);

    const Pagination = new PaginationHelper(tags);

    const getTags = () => {
        Api
            .get('/timeline/tags')
            .then(
                res => {
                    setTags(res.data);
                }
            )
            .catch()
    }

    const sortByName = () => {
        if(nameAsc) {
            tags.sort((a, b)=>a.name.localeCompare(b.name))
        } else {
            tags.sort((a, b)=>b.name.localeCompare(a.name))
        }
        setNameAsc(nameAsc => !nameAsc)
    }

    const sortBySlug = () => {
        if(slugAsc) {
            tags.sort((a, b) => a.slug.localeCompare(b.slug))
        } else {
            tags.sort((a,b)=>b.slug.localeCompare(a.slug));
        }
        setSlugAsc(slugAsc => !slugAsc)
    }

    useEffect(()=>{
        getTags()
        console.log(Pagination.returnPage(1))
    }, [])

    return {
        tags: tags,
        getTags,
        sortByName,
        sortBySlug,
        nameAsc,
        slugAsc
    }
}