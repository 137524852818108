import * as React from 'react';
import {MyMasonsProfilePage} from "../pages/freemasons_profile/MyMasonsProfilePage";

export const masonsProfileRoutes = [
    {
        path: '/my/masons_profile',
        component: MyMasonsProfilePage,
        isPrivate: true
    },
    {
        path: '/masons_profile/manage',
        component: MyMasonsProfilePage,
        isPrivate: true
    }
]