import * as React from 'react';

import {staticRoutes} from "./StaticRoutes";
import {accountRoutes} from "./AccountRoutes";
import {dashboardRoutes} from "./DashboardRoutes";
import {timelineRoutes} from "./TimelineRoutes";
import {servingMembersRoutes} from "./ServingMembersRoutes";
import {profileRoutes} from "./ProfileRoutes";
import {masonsProfileRoutes} from "./MasonsProfileRoutes";
import {PortraitRoutes} from "./PortraitRoutes";
import {lodgeRoutes} from "./LodgeRoutes";
import {NotFoundPage} from "../pages/common/404.page";

//@ts-ignore
const routesBase:Array = []

routesBase.push(...staticRoutes)
routesBase.push(...accountRoutes)
routesBase.push(...dashboardRoutes)
routesBase.push(...timelineRoutes)
routesBase.push(...servingMembersRoutes)
routesBase.push(...profileRoutes)
routesBase.push(...masonsProfileRoutes)
routesBase.push(...PortraitRoutes)
routesBase.push(...lodgeRoutes)
routesBase.push(
    {
        path: '/*',
        component: NotFoundPage
    }
)

export default routesBase