import * as React from 'react';
import {ICalendarEvent} from "../../../interfaces/Calendar";
import {ModalBody, ModalHeader} from "reactstrap";
import {BsClockFill, BsGeoAlt, BsInfoCircle} from "react-icons/all";
import {DateTime} from "luxon";
import {AddressFull} from "../../address/molecules/AddressFull";

export const CalendarLodgeEvent = (props:ICalendarEvent) => {
    //@ts-ignore
    const event_date_time = new DateTime.fromISO(props.date_time);
    return (
        <>
            <ModalHeader>{props.title} - {props.type}</ModalHeader>
            <ModalBody>
                <div className={"row"}>
                    <div className={"col-auto"}><BsClockFill/></div>
                    <div className={"col"}>{event_date_time.toLocaleString(DateTime.DATETIME_MED)}</div>
                </div>
                <div className={"row"}>
                    <div className={"col-auto"}><BsGeoAlt/></div>
                    <div className={"col"}><AddressFull {...props.location}/></div>
                </div>
                <div className={"row"}>
                    <div className={"col-auto"}><BsInfoCircle/></div>
                    <div className={"col"}>This event is a Freemasons meeting, it is not open to the general public.</div>
                </div>
            </ModalBody>
        </>
    )
}
