import * as React from 'react';
import {useEffect, useState} from "react";
import {ITimelineEvent, ITimelineTag} from "../../interfaces/Timeline";
import Api from "../../config/Api";
import {useAuthState} from "../../contexts/auth/authContext";
import { DateTime } from 'luxon';

export const useTimelineForm = (
    updatePreview?:(event:ITimelineEvent)=>void,
    updateSavedEvents?:(event:ITimelineEvent)=>void
) => {
    const [id, setId] = useState<string>('');
    const [date, setDate] = useState<DateTime>(DateTime.now());
    const [description, setDescription] = useState<string>('');
    const [showMonth, setShowMonth] = useState<boolean>(false);
    const [showDay, setShowDay] = useState<boolean>(false)
    const [tags, setTags] = useState<ITimelineTag[]>([]);
    const [availableTags, setAvailableTags] = useState<ITimelineTag[]>([])
    const [formErrors, setFormErrors] = useState({
        description:'',
        date:''
    });

    const [loading, setLoading] = useState(false);

    const user = useAuthState();

    const changeDate = (date:DateTime) => {
        setDate(date);
    }
    const changeDescription = (e:React.ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    }
    const changeTags = (e:any) => {
        const eventTags:ITimelineTag[] = [];
        const selectedOptions = Array.from(e.target.selectedOptions);
        selectedOptions.map((option:any) => {
            eventTags.push({
                name:option.innerHTML,
                slug:option.value
            })
        })
        setTags(eventTags);
    }

    const toggleShowMonth = () => setShowMonth(!showMonth)
    const toggleShowDay = () => setShowDay(!showDay)
    const clearForm = () => {
        setId('');
        setDescription('');
        setShowMonth(false);
        setShowDay(false);
        setTags([]);
        setFormErrors({
            description: '',
            date: ''
        });
    }

    const validation = () => {
        let valid = true;
        const errors:{description:string, date:string} = {description:'',date:''};
        if(description.length < 5) {
            errors.description = "Please enter a description";
            valid = false;
        }
        if(!date) {
            errors.date = "Please enter a valid date";
            valid = false;
        }
        setFormErrors(errors);
        return valid;
    }

    const handleSubmit = () => {
        if(!validation()) return;
        try {
            setLoading(true);
            // let dt = DateTime.fromFormat(date, "dd/MM/yyyy");
            const postData ={
                date:date.toISODate(),
                description:description,
                tags:JSON.stringify(tags),
                show_month:showMonth,
                show_day:showDay
            };
            Api
                .post('/timeline/create', postData, {
                    headers: {
                        'Authorization': `Token ${user.token}`
                    }
                })
                .then(
                    res => {
                        setLoading(false);
                        const savedEvent:ITimelineEvent = res.data;
                        savedEvent.date = DateTime.fromISO(res.data.date);
                        if(typeof updateSavedEvents !== "undefined") {
                            updateSavedEvents(savedEvent);
                        }
                        clearForm();
                    }
                )
                .catch(
                    err => {
                        console.log(err)
                    }
                )
        } catch (e) {}
    }

    useEffect(() => {
        Api
            .get('/timeline/tags')
            .then(
                res => {
                    setAvailableTags(res.data);
                }
            )
            .catch()
    }, [])

    useEffect(() => {
        if(typeof updatePreview !== "undefined") {
            updatePreview({
                _id:'',
                date:date,
                description:description,
                tags:tags,
                show_month:showMonth,
                show_day:showDay
            })
        }
    }, [date, description, tags, showMonth, showDay])

    return {
        date,
        showMonth, showDay, toggleShowMonth, toggleShowDay,
        description, changeDescription,
        tags, changeTags,
        availableTags,
        handleSubmit,
        changeDate,
        formErrors
    }
}