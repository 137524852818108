import {Form, FormFeedback, FormGroup, FormText, Input, Label} from "reactstrap";
import {useAddressForm} from "../../../states/address/useAddressForm";
import {IAddress} from "../../../interfaces/MeetingHall";

export const AddressForm = ({givenAddress}:{givenAddress:IAddress}) => {
    const {
        updateValue,
        errors,
        thisAddress
    } = useAddressForm({givenAddress:givenAddress});
    return (
        <Form onSubmit={e=>e.preventDefault()}>
            <FormGroup>
                <Label for={"address_name"}>Address Name</Label>
                <Input name={"address_name"} type={"text"} onChange={updateValue} value={thisAddress.name} placeholder={"Grand Lodge"}/>
            </FormGroup>
            <FormGroup>
                <Label for={"line_1"}>Line 1*</Label>
                <Input name={"line_1"} type={"text"} onChange={updateValue} value={thisAddress.line_1} placeholder={"United Grand Lodge of England"} required invalid={errors.hasOwnProperty('line_1')}/>
                <FormFeedback>{errors.hasOwnProperty('line_1') && errors.line_1}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for={"line_2"}>Line 2</Label>
                <Input name={"line_2"} type={"text"} onChange={updateValue} value={thisAddress.line_2} placeholder={"Great Queen Street"}/>
            </FormGroup>
            <FormGroup>
                <Label for={"city"}>City</Label>
                <Input name={"city"} type={"text"} onChange={updateValue} value={thisAddress.city} placeholder={"London"}/>
            </FormGroup>
            <FormGroup>
                <Label for={"county"}>County</Label>
                <Input name={"county"} type={"text"} onChange={updateValue} value={thisAddress.county} placeholder={"London"}/>
            </FormGroup>
            <FormGroup>
                <Label for={"country"}>County</Label>
                <Input name={"country"} type={"text"} onChange={updateValue} value={thisAddress.country} placeholder={"United Kingdom"}/>
            </FormGroup>
            <FormGroup>
                <Label for={"postcode"}>Postcode</Label>
                <Input name={"postcode"} type={"text"} onChange={updateValue} value={thisAddress.postcode} placeholder={"WC2B 5AZ"}/>
            </FormGroup>
            <FormGroup>
                <Label for={"address_phone"}>Phone</Label>
                <Input name={"address_phone"} type={"text"} onChange={updateValue} value={thisAddress.phone} placeholder={"00000000000"}/>
            </FormGroup>
            <FormGroup>
                <Label for={"address_email"}>Email</Label>
                <Input name={"address_email"} type={"text"} onChange={updateValue} value={thisAddress.email} placeholder={"abc@email.com"}/>
            </FormGroup>
        </Form>
    )
}