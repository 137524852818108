import * as React from 'react';
import {ITimelineEvent, ITimelineTag} from "../../../interfaces/Timeline";
import {Col, Row} from "reactstrap";
import Moment from "react-moment";
import {DateTime} from "luxon";
import {TimelineDescription} from "../atoms/TimelineDescription";
import {TimelineTagModal} from "../atoms/TimelineTagModal";
import {TimelineTagLink} from "../atoms/TimelineTagLink";

type myProps = {
    event: ITimelineEvent,
    follow_link?: boolean | undefined
}

const defaultProps = {
    follow_link: true
}

export const TimelineEvent = (props:myProps) => {
    let eTags:ITimelineTag[] = props.event.tags;

    const format = (!props.event.show_month)?'yyyy':(!props.event.show_day)?'yyyy MMMM':'yyyy MMMM d';

    return (
        <Row className={"my-3"} id={props.event._id}>
            <Col md={3}>
                {/*<h2 className={"font-accent"}>{props.event.date.toLocaleString(DateTime.DATE_MED)}</h2>*/}
                <h2 className={"font-accent"}>{props.event.date.toFormat(format)}</h2>
            </Col>
            <Col md={7}>
                <TimelineDescription content={props.event.description}/>
            </Col>
            <Col md={2}>
                {props.event.tags.length < 1?(<div>None</div>):props.event.tags.length > 1?<TimelineTagModal tags={props.event.tags} color={"fmis-primary"}/>:<TimelineTagLink follow_link={props.follow_link} color={"fmis-primary"} location={"/history"+props.event.tags[0].slug} content={props.event.tags[0].name}/>}
            </Col>
        </Row>
    )
}