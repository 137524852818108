import * as React from 'react';
import {useState} from "react";

export const useCalendar = () => {
    const [value, setValue] = useState(new Date());
    const onChange = (nextValue:any) => {
        setValue(nextValue);
    }



    return {value, onChange};
}