import * as React from 'react';
import {UserDashboardTemplate} from "../../components/templates/UserDashboardTemplate";
import {useAuthState} from "../../contexts/auth/authContext";
import {DashboardGreeting} from "../../components/dashboard/atoms/DashboardGreeting";
import {useHistory} from "react-router-dom";
import {useContext, useEffect, useState} from "react";

export const DashboardPage = () => {
    const {user} = useAuthState();

    if(typeof user === "undefined") {
        return <UserDashboardTemplate content={"No user found"}/>
    }

    return (
        <UserDashboardTemplate content={
            <>
                <DashboardGreeting user={user}/>
            </>
        }/>
    )
}