import React from 'react';
import logo from './logo.svg';
import './App.css';
import './styles/global.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routesBase from "./routes/routesBase";
import {AuthProvider} from "./contexts/auth/authContext";
import {ProtectedRoute} from "./components/route_handlers/ProtectedRoute";


function App() {
    // @ts-ignore
    function RouteWithSubRoutes(route) {
        if(route.isPrivate) {
            return <ProtectedRoute authentication_path={'/login'} {...route}/>
        }
        return (
            <Route
              path={route.path}
              render={props=>(
                  <route.component {...props} routes={route.routes}/>
              )}
            />
        )
    }
    return (
        <Switch>
            {routesBase.map((route: any, i:number) => (
                <RouteWithSubRoutes key={i} {...route}/>
            ))}
        </Switch>
  )
}

export default App;
