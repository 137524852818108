import * as React from 'react';
import {useRegistrationForm} from "../../../states/user/useRegistrationForm";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";

export const RegistrationForm = () => {
    const { emailChange, passwordChange, displayNameChange, loading, submit } = useRegistrationForm();

    return (
        <Form>
            <FormGroup>
                <Label for={"email"}>Email address</Label>
                <Input type={"text"} name={"email"} onChange={emailChange}/>
            </FormGroup>
            <FormGroup>
                <Label for={"password"}>Password</Label>
                <Input type={"password"} name={"password"} onChange={passwordChange}/>
            </FormGroup>
            <Button onClick={submit} color={"primary"} block disabled={loading}>Register</Button>
        </Form>
    )
}