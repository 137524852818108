import * as React from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import {useState} from "react";
import {useNavBar} from "../../states/components/useNavBar";
import {BsPersonFill} from "react-icons/all";
import {ProfileQuickMenu} from "../user/molecules/ProfileQuickMenu";
import {useAuthState} from "../../contexts/auth/authContext";
import {LoginModal} from "../user/organisms/LoginModal";

export const Header = () => {
    const {isOpen, toggle} = useNavBar();
    const {user} = useAuthState();

    return (
        <div>
            <Navbar color="fmis-secondary" light expand="md">
                <NavbarBrand href="/" className={"font-accent"}>Freemasonry in Sheffield</NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="d-flex justify-content-between w-100 px-lg-2" navbar>
                        <div className={"d-flex justify-content-start"}>
                            <NavItem>
                                <NavLink href="/home">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/history">History</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/calendar">Calendar</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/lodges">Lodges</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/library">Library</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/about">About</NavLink>
                            </NavItem>
                        </div>
                        <NavItem>
                            <NavLink href="/contact" className={"btn-fmis-primary"} style={{color:'#fff'}}>Ask a question</NavLink>
                        </NavItem>
                    </Nav>
                    {/*{(user) && <ProfileQuickMenu/>}*/}
                    {/*{(!user) && <LoginModal/>}*/}
                </Collapse>
            </Navbar>
        </div>
    );
}