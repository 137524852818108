import {ILodge} from "../../../interfaces/Lodge";
import {LodgeNumberBadge} from "../../lodge/atoms/LodgeNumberBadge";
import {LodgeLink} from "../../lodge/molecules/LodgeLink";

export const MotherLodgeDisplay = ({lodge}:{lodge:ILodge}) => {
    return (
        <>
            <h1>Mother Lodge:</h1>
            <LodgeLink name={lodge.name} location={lodge.slug} number={lodge.number}/>
        </>
    )
}