import * as React from 'react';
import {IUser} from "../../interfaces/User";
import {AuthReducer, authState, initialState} from "../../reducers/AuthReducer";
import {useReducer} from "react";

// type AuthContextType = {
//     user?: IUser
//     token?: string
//     login: (username:string, password:string) => void
//     logout: () => void
// }

//@ts-ignore
const AuthStateContext = React.createContext<authState>(null);
const AuthDispatchContext = React.createContext<any>(null);

export function useAuthState() {
    const context = React.useContext(AuthStateContext);
    if(context === undefined) throw new Error('useAuthState requires AuthStateContext')
    return context;
}

export function useAuthDispatch() {
    const context = React.useContext(AuthDispatchContext);
    if(context === undefined) throw new Error('useAuthDispatch requires AuthDispatchContext')
    return context;
}

//@ts-ignore
export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(AuthReducer, initialState);

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}
