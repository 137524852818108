import * as React from 'react';
import {useTimelineForm} from "../../../states/timeline/useTimelineForm";
import {Button, CustomInput, Form, FormFeedback, FormGroup, Input, Row} from "reactstrap";
import {TimelineEvent} from "../molecules/TimelineEvent";
import {DateSelector} from "../../misc/forms/organisms/DateSelector";
import {ITimelineEvent} from "../../../interfaces/Timeline";

export const TimelineEventForm = (
    {updatePreview, updateSavedEvents}:{updatePreview?:(event:ITimelineEvent)=>void, updateSavedEvents?:(event:ITimelineEvent)=>void}
) => {
    const {
        date,
        showMonth, showDay, toggleShowMonth, toggleShowDay,
        description, changeDescription,
        tags, changeTags,
        availableTags,
        handleSubmit,
        changeDate,
        formErrors
    } = useTimelineForm(updatePreview, updateSavedEvents);

    return (
        <Form inline className={"align-items-start"}>
            <FormGroup className={"flex-column col-3"}>
                <DateSelector onChangeFunc={changeDate}/>
                <div>
                    <CustomInput id={"showMonthSwitch"} type={"switch"} onChange={toggleShowMonth} label={"Show Month?"}/>
                    <CustomInput id={"showDaySwitch"} type={"switch"} onChange={toggleShowDay} disabled={!showMonth} label={"Show Day?"}/>
                </div>
                <FormFeedback>{formErrors.date}</FormFeedback>
            </FormGroup>
            <FormGroup className={"col-7"}>
                <Input type={"textarea"} onChange={changeDescription} className={"w-100"} style={{minHeight:"100px"}} invalid={!!formErrors.description}/>
                <FormFeedback>{formErrors.description}</FormFeedback>
            </FormGroup>
            <FormGroup className={"col-2"}>
                <Input type={"select"} onChange={changeTags} multiple>
                    {availableTags.map(tag => {
                        return (
                            <option key={tag.slug} value={tag.slug}>{tag.name}</option>
                        )
                    })}
                </Input>

            </FormGroup>
            <Button onClick={handleSubmit} color={"fmis-primary"} className={"ml-auto my-2"}>Save</Button>
        </Form>
    )
}