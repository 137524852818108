import {IArtist} from "../../interfaces/User";
import {SyntheticEvent, useEffect, useState} from "react";
import {useAddressForm} from "../address/useAddressForm";
import {DefaultAddress} from "../../defaults/address.default";
import {IValidationResult} from "../../interfaces/Validation";

type myType = {
    givenArtist: IArtist
}

export const useArtistForm = ({givenArtist}:myType) => {
    const [thisArtist, setThisArtist] = useState<IArtist>(givenArtist);
    const [errors, setErrors] = useState<IValidationResult>({});

    const {
        thisAddress,
        updateValue: updateAddressValue,
        errors: addressErrors,
        validation: addressValidation
    } = useAddressForm({givenAddress:givenArtist.address || DefaultAddress});

    const updateValue = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement;
        switch (target.name) {
            case "artist_name":
                setThisArtist(thisArtist => ({...thisArtist, name: target.value}));
                break;
            case "artist_website":
                setThisArtist(thisArtist => ({...thisArtist, website: target.value}));
                break;
        }
    }
    const validation = () => {
        if(thisArtist.name.trim().length < 1) {
            setErrors(errors => ({
                ...errors, ["artist_name"]:["Cannot be empty"]}))
        }
        addressValidation();
    }

    useEffect(()=>{
        setThisArtist(thisArtist => ({...thisArtist, address: thisAddress}));
    }, [thisAddress])
    useEffect(()=>{
        setErrors(errors => ({
            ...errors,
            ...addressErrors
        }))
    }, [addressErrors])

    return {
        thisArtist,
        updateValue,
        updateAddressValue,
        validation,
        errors
    }
}