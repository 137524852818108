import * as React from 'react';
import {ILodge} from "../../../interfaces/Lodge";
import {LodgeLink} from "./LodgeLink";
import {LodgeMeetingText} from "../atoms/LodgeMeetingText";
import {IMeetingHall} from "../../../interfaces/MeetingHall";
import {IRegularMeeting} from "../../../interfaces/RegularMeeting";
import {LodgeMinorTable} from "../atoms/LodgeMinorTable";

type MyProps = {
    lodge: ILodge
}

export const LodgeListItem = (props:MyProps) => {
    return (
        <div className={"col-12"}>
            <h1 className={"text-responsive"}><LodgeLink name={props.lodge.name} location={'/lodge/'+props.lodge.slug} number={props.lodge.number}/></h1>
            <LodgeMeetingText regular_meeting={props.lodge.regular_meeting}/>
            <LodgeMinorTable consecration_year={props.lodge.consecrated} location_name={props.lodge.regular_meeting.meeting_hall.name}/>
        </div>
    )
}