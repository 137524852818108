import * as React from 'react';
import {useContactForm} from "../../../states/useContactForm";
import {Alert, Button, Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {Simulate} from "react-dom/test-utils";

export const ContactForm = () => {
    const {
        contact_name, changeContactName,
        email, changeEmail,
        emailTwo, changeEmailTwo,
        phone_number, changePhoneNumber,
        message, changeMessage,
        formMessage,
        formErrors,
        loading,
        submit
    } = useContactForm();

    return (
        <Form>
            <h2>Get in touch</h2>
            <FormGroup>
                <Label for={"contact_name"}>Name</Label>
                <Input type={"text"} name={"contact_name"} onChange={changeContactName} value={contact_name} invalid={(formErrors.contact_name !== undefined)}/>
                {((formErrors.contact_name) && <FormFeedback>{formErrors.contact_name}</FormFeedback>)}
            </FormGroup>
            <FormGroup>
                <Label for={"email"}>Email address</Label>
                <Input type={"email"} name={"email"} onChange={changeEmail} value={email} invalid={(formErrors.email !== undefined)}/>
                {((formErrors.email) && <FormFeedback>{formErrors.email}</FormFeedback>)}
            </FormGroup>
            <FormGroup className={"d-none"}>
                <Label for={"email_two"}>Email confirm</Label>
                <Input type={"text"} name={"email_two"} onChange={changeEmailTwo}/>
            </FormGroup>
            <FormGroup>
                <Label for={"phone_number"}>Phone number</Label>
                <Input type={"text"} name={"phone_number"} onChange={changePhoneNumber} value={phone_number} invalid={(formErrors.phone_number !== undefined)}/>
                {((formErrors.phone_number) && <FormFeedback>{formErrors.phone_number}</FormFeedback>)}
            </FormGroup>
            <FormGroup>
                <Label for={"message"}>Message</Label>
                <Input type={"textarea"} name={"message"} onChange={changeMessage} value={message} invalid={(formErrors.message !== undefined)}/>
                {(formErrors.message && <FormFeedback>{formErrors.message}</FormFeedback>)}
            </FormGroup>
            <Button onClick={submit} disabled={loading}>Submit</Button>
            {(formMessage.message.length > 0 && <Alert color={formMessage.color}>{formMessage.message}</Alert>)}
        </Form>
    )
}