import * as React from 'react';
import Api from "../../config/Api";
import {useEffect, useState} from "react";
import {IProfile} from "../../interfaces/User";

export const useServingMembersByLodge = (lodge_slug:string) => {
    const [profiles, setProfiles] = useState<IProfile[]>([]);
    const [activeTab, setActiveTab] = useState('');

    const changeActiveTab = (key:string) => {
        if(activeTab !== key) setActiveTab(key);
    }

    useEffect(() => {
        Api
            .get('servingMembers/byMotherLodge/'+lodge_slug)
            .then(
                res => {
                    setProfiles(res.data);
                }
            )
    }, [])

    return {
        profiles,
        activeTab,
        changeActiveTab
    }
}