import * as React from 'react';
import {useState} from "react";
import Api from "../../config/Api";
import {useHistory} from "react-router-dom";
import {useAuthState} from "../../contexts/auth/authContext";

export const useRegistrationForm = () => {
    const [email, setEmail] = useState();
    const [display_name, setDisplayName] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const user = useAuthState();

    const submit = async(event:any) => {
        event.preventDefault();
        setLoading(true);
        try{
            const postData = {
                user:{
                    email:email,
                    password: password
                }
            }
            await Api
                .post('/account/', postData, {
                    headers: {
                        'Authorization': `Token ${user.token}`
                    }
                })
                .then(
                    res=>{
                        return history.push('/dashboard');
                    }
                )
        } catch(e) {

        }
        setLoading(false)
    }

    const emailChange = (event:any) => {
        setEmail(event.target.value);
    }

    const displayNameChange = (event:any) => {
        setDisplayName(event.target.value);
    }

    const passwordChange = (event:any) => {
        setPassword(event.target.value);
    }

    return {emailChange, passwordChange, displayNameChange, loading, submit}
}