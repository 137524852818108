import * as React from 'react';

import HomePage from "../pages/static/HomePage";
import {TimelinePage} from "../pages/timeline/TimelinePage";
import {CalendarPage} from "../pages/calendar/CalendarPage";
import {LodgeListPage} from "../pages/lodge/LodgeListPage";
import {AboutPage} from "../pages/static/AboutPage";
import {ContactPage} from "../pages/static/ContactPage";
import LibraryPage from "../pages/library/LibraryPage";

export const staticRoutes = [
    {
        path: '/',
        component: HomePage,
        exact: true
    },
    {
        path: '/home',
        component: HomePage,
    },
    {
        path: '/history/:slug',
        component: TimelinePage
    },
    {
        path: '/history',
        component: TimelinePage
    },
    {
        path: '/calendar',
        component: CalendarPage
    },
    {
        path: '/lodges',
        component: LodgeListPage
    },
    {
        path: '/about',
        component: AboutPage
    },
    {
        path: '/contact',
        component: ContactPage
    },
    {
        path: '/library',
        component: LibraryPage
    }
]
