import * as React from 'react';
import {GradientImageLink} from "../../components/layout/GradientImageLink";
import {FullWidthTemplate} from "../../components/templates/FullWidthTemplate";

const HomePage = () => {
    const content = (
        <div className={"p-0 container-fluid"}>
            <div className={"row no-gutters screen-height"}>
                <div className={"col-lg-6 d-none d-lg-block flex-fill"}>
                    <div className={"row h-100 no-gutters"}>
                        <GradientImageLink className={"col-12"} image={"CaptureClassic.PNG"} text={"Freemasonry in Sheffield"}/>
                    </div>
                </div>
                <div className={"col-12 col-lg-6 flex-fill"}>
                    <div className={"row h-50 no-gutters"}>
                        <GradientImageLink className={"col-12"} image={"Discovering_Freemasonry.jpg"} href={'contact'} text={"Becoming a Freemason"}/>
                    </div>
                    <div className={"row h-50 no-gutters d-flex"}>
                        <GradientImageLink className={"col-6"} image={"freemasonsHall_Hero.jpg"} href={'history'} text={"History"}/>
                        <GradientImageLink className={"col-6"} image={"eric-rothermel-FoKO4DpXamQ-unsplash.jpg"} href={'calendar'} text={'Calendar'}/>
                    </div>
                </div>
            </div>
        </div>
        )
    return (
        <FullWidthTemplate content={content}/>
    )
}

export default HomePage