import * as React from 'react';

type myProps = {
    href?: string,
    text: string
}

export const GradientLink = (props:myProps) => {
    if(!props.href) {
        return (
            <div className={"d-flex text-white h-100 bg-gradient-up align-items-end"}>
                <h2 className={"m-4"}>{props.text}</h2>
            </div>
        )
    }
    return (
        <a className={"d-flex text-white h-100 bg-gradient-up align-items-end"} href={props.href}>
            <h2 className={"m-4"}>{props.text}</h2>
        </a>
    )
}