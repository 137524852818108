import * as React from 'react';
import {IAddress} from "../../../interfaces/MeetingHall";
import List from "reactstrap/es/List";
import {BsGeoAlt} from "react-icons/all";

export const AddressFull = (address:IAddress) => {
    return (
        <List type={"unstyled"}>
            <li>{address.line_1}</li>
            <li>{address.line_2}</li>
            <li>{address.city}</li>
            <li>{address.county}</li>
            <li>{address.postcode}</li>
        </List>
    )
}