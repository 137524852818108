import {IAddress} from "../../interfaces/MeetingHall";
import {SyntheticEvent, useState} from "react";
import {IValidationResult} from "../../interfaces/Validation";

type myType = {
    givenAddress: IAddress
}

export const useAddressForm = ({givenAddress}:myType) => {
    const [thisAddress, setThisAddress] = useState<IAddress>(givenAddress);
    const [errors, setErrors] = useState<IValidationResult>({});

    const updateValue = (e:SyntheticEvent) => {
        const target = e.target as HTMLInputElement, value = target.value, name = target.name;
        switch(name) {
            case "address_name":
                setThisAddress(thisAddress => ({...thisAddress, name: value}));
                break;
            case "line_1":
                setThisAddress(thisAddress =>({...thisAddress, line_1: value}));
                break;
            case "line_2":
                setThisAddress(thisAddress =>({...thisAddress, line_2: value}));
                break;
            case "city":
                setThisAddress(thisAddress =>({...thisAddress, city: value}));
                break;
            case "county":
                setThisAddress(thisAddress =>({...thisAddress, county: value}));
                break;
            case "country":
                setThisAddress(thisAddress =>({...thisAddress, country: value}));
                break;
            case "postcode":
                setThisAddress(thisAddress =>({...thisAddress, postcode: value}));
                break;
            case "address_phone":
                setThisAddress(thisAddress =>({...thisAddress, phone: value}));
                break;
            case "address_email":
                setThisAddress(thisAddress =>({...thisAddress, email: value}));
                break;
        }
    }
    const validation = () => {
        if(thisAddress.line_1.trim().length < 1) {
            setErrors(errors => ({
                ...errors, ["line_1"]:["Cannot be empty"]}))
        }
    }

    return {
        thisAddress,
        updateValue,
        validation,
        errors
    }
}