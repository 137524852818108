import * as React from 'react';
import {ListGroupItem} from "reactstrap";
import {ITimelineYear} from "../../../interfaces/Timeline";
import Moment from "react-moment";

type MyProps = {
    location: string,
    year: string,
    active?: boolean,
    color?: string
};

const defaultProps = {
    color: "fmis-primary",
    active: false
}

export const TimelineYear = (props:MyProps) => {
    const yearToShow = props.year;

    return (
        <ListGroupItem
            href={props.location}
            active={props.active}
            tag={'a'}
            color={props.color}
            className={"text-right"}
            action
        >
            {yearToShow}
        </ListGroupItem>
    )
}

TimelineYear.defaultProps = defaultProps;