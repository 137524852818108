import * as React from 'react';
import {useState} from "react";
import {useAuthDispatch, useAuthState} from "../../contexts/auth/authContext";
import {loginUser} from "../../contexts/auth/actions";
import {Redirect, useHistory} from 'react-router-dom';

export const useLoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();

    const dispatch = useAuthDispatch();
    const {loading, errorMessage} = useAuthState();

    const login = async(event:any) => {
        try {
            await loginUser(dispatch, { username, password })
                .then(
                    response => {
                        if(response.user) {
                            history.push('/dashboard')
                        }
                    }
                );
        } catch (error) {
        }
    }

    const usernameChange = (event:any) => {
        setUsername(event.target.value);
    }

    const passwordChange = (event:any) => {
        setPassword(event.target.value);
    }

    return {usernameChange, passwordChange, loading, errorMessage, login}
}