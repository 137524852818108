import {ListGroup, ListGroupItem} from "reactstrap";
import {DateTime} from "luxon";

export const MasonsRankProgressTable = ({initiation, passing, raising}:{initiation:string, passing?:string, raising?:string}) => {
    return (
        <ListGroup horizontal>
            <ListGroupItem>Initiated: {DateTime.fromFormat(initiation, 'dd/MM/yyyy').toLocaleString(DateTime.DATE_HUGE)}</ListGroupItem>
            <ListGroupItem>Passed: {passing
                ? DateTime.fromFormat(passing, 'dd/MM/yyyy').toLocaleString(DateTime.DATE_HUGE)
                : 'N/A'
            }</ListGroupItem>
            <ListGroupItem>Raised: {raising
                ? DateTime.fromFormat(raising, 'dd/MM/yyyy').toLocaleString(DateTime.DATE_HUGE)
                : 'N/A'
            }</ListGroupItem>
        </ListGroup>
    )
}