import * as React from 'react';
import {useCalendarEvents} from "../../states/calendar/useCalendarEvents";
import {FullWidthTemplate} from "../../components/templates/FullWidthTemplate";
import {CalendarOrganism} from "../../components/calendar/organisms/CalendarOrganism";

export const CalendarPage = (props:any) => {
    const {events} = useCalendarEvents();
    
    return (
        <FullWidthTemplate content={<CalendarOrganism events={events}/>} />
    )
}