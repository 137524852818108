import {useAuthState} from "../../contexts/auth/authContext";
import {MasonsProfileForm} from "../../components/user/molecules/MasonsProfileForm";
import {UserDashboardTemplate} from "../../components/templates/UserDashboardTemplate";
import {useMasonsProfileEditPage} from "../../states/masonic_profile/useMasonsProfileEditPage";
import {MasonsProfile} from "../../components/profile/organisms/MasonsProfile";

export const MyMasonsProfilePage = () => {
    const {user} = useAuthState();
    const {
        profilePreview,
        changeProfilePreview
    } = useMasonsProfileEditPage();

    const pageContent = (
        <>
            <h1>Your Freemasons profile</h1>
            <MasonsProfileForm props={user} updatePreview={changeProfilePreview}/>
            <MasonsProfile profile={profilePreview}/>
        </>
    )

    return (
        <UserDashboardTemplate content={pageContent}/>
    )
}