import * as React from 'react';
import {useLoginForm} from "../../../states/user/useLoginForm";
import {Form, FormGroup, Label, Input, Button } from "reactstrap";
import {useHistory} from "react-router-dom";

export const LoginForm = () => {
    const { usernameChange, passwordChange, loading, errorMessage, login } = useLoginForm();

    return (
        <Form className={"col-12"}>
            <FormGroup>
                <Label for={"username"}>Username</Label>
                <Input type={"text"} name={"username"} id={"username"} onChange={usernameChange}/>
            </FormGroup>
            <FormGroup>
                <Label for={"password"}>Password</Label>
                <Input type={"password"} name={"password"} id={"password"} onChange={passwordChange}/>
            </FormGroup>
            <FormGroup>
                {errorMessage}
            </FormGroup>
            <Button onClick={login} color={"primary"} block disabled={loading}>Login</Button>
        </Form>
    )
}