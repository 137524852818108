import * as React from 'react';
import {ITimelineYear} from "../../../interfaces/Timeline";
import {TimelineYear} from "../atoms/TimelineYear";
import {ListGroup, ListGroupItem, Spinner} from "reactstrap";

type MyProps = {
    years: ITimelineYear[],
    loading: boolean
}

const defaultProps = {
    years: [],
    boolean: true
}

export const TimelineYears = (props:MyProps) => {
    if(props.loading) {
        return (
            <>
                <ListGroup flush>
                    <Spinner />
                </ListGroup>
            </>
        )
    }

    if(props.years.length === 0) {
        return (
            <>
                <ListGroup flush>

                </ListGroup>
            </>
        )
    }

    const filteredYears = props.years.filter( (ele, ind) => ind === props.years.findIndex( elem => elem.year === ele.year && elem.id === ele.id))
    return (
        <div className={"d-flex justify-content-end align-items-center"}>
            <ListGroup flush>
                {
                    filteredYears.map(
                        year => <TimelineYear key={year.id} color={"fmis-primary"} year={year.year} location={'#'+year.id} />
                    )
                }
            </ListGroup>
        </div>
    )
}

TimelineYears.defaultProps = defaultProps;