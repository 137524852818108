import * as React from 'react';
import {IProfile} from "../../../interfaces/User";
import {TabContent, TabPane} from "reactstrap";
import {Profile} from "./Profile";

export const ProfileTabPane = ({profiles, activeTab}:{profiles:IProfile[], activeTab:string}) => {
    return (
        <TabContent activeTab={activeTab}>
            {profiles.map(profile=>{
                return(
                    <TabPane tabId={profile.slug}>
                        <Profile profile={profile}/>
                    </TabPane>
                )
            })}
        </TabContent>
    )
}