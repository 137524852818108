import * as React from 'react';
import {ListGroup, ListGroupItem, Nav} from "reactstrap";
import {useServingMembersByLodge} from "../../../states/serving_members/useServingMembersByLodge";
import {ServingMemberListItem} from "./ServingMemberListItem";
import {IProfile} from "../../../interfaces/User";

export const ServingMembersList = ({profiles, changeTab}:{profiles:IProfile[], changeTab:(key:string)=>void}) => {

    return (
        <div className={"d-flex h-100 justify-content-end align-items-center"}>
            <Nav vertical tabs>
                {profiles.map(profile => {
                    return (
                        <ServingMemberListItem
                            first_name={profile.first_name}
                            last_name={profile.last_name}
                            rank={profile.military_profile?.rank}
                            slug={profile.slug}
                            setActiveProfile={changeTab}
                            isActive={false}
                        />
                    )
                })}
            </Nav>
        </div>
    )
}