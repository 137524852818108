import * as React from 'react';
import {UserDashboardTemplate} from "../../components/templates/UserDashboardTemplate";
import {TimelineEventForm} from "../../components/timeline/organisms/TimelineEventForm";
import {TimelineEvent} from "../../components/timeline/molecules/TimelineEvent";
import {useTimelineEditPage} from "../../states/timeline/useTimelineEditPage";
import {Card, CardBody, CardHeader} from "reactstrap";

export const TimelineEventEditPage = () => {
    const {
        eventPreview,
        changeEventPreview,
        changeSavedEvents,
        savedEvents
    } = useTimelineEditPage();

    return (
        <UserDashboardTemplate content={
            (
                <>
                    <h1>Add a timeline event</h1>
                    <TimelineEventForm updatePreview={changeEventPreview} updateSavedEvents={changeSavedEvents}/>
                    <Card>
                        <CardHeader>Preview</CardHeader>
                        <CardBody>
                            <TimelineEvent event={eventPreview} follow_link={false}/>
                        </CardBody>
                    </Card>
                    {savedEvents.length > 0?(
                        <Card>
                            <CardHeader className={"bg-success text-white"}>Saved Events</CardHeader>
                            <CardBody>
                                {savedEvents.map(event => {
                                    return <TimelineEvent event={event} follow_link={false}/>
                                })}
                            </CardBody>
                        </Card>
                    ):''}
                </>
            )
        }/>
    )
}