export const NotFoundOrganism = () => {
    return (
        <>
            <div className={"container-fluid screen-sandwich"}>
                <div className={"row screen-sandwich"}>
                    <div className={"col"}>
                        <h1 className={"text-center"}>404 - Sorry we couldn't find what you were looking for.</h1>
                    </div>
                </div>
            </div>
        </>
    )
}