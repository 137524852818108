import * as React from 'react';
import {useModal} from "../../../states/components/useModal";
import {Badge, Button, Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import {IAddress} from "../../../interfaces/MeetingHall";
import {DateTime} from "luxon";
import {ICalendarEvent} from "../../../interfaces/Calendar";
import {BsClockFill, BsGeoAlt, BsHouseDoorFill} from "react-icons/all";
import {AddressFull} from "../../address/molecules/AddressFull";
import {CalendarLodgeEvent} from "./CalendarLodgeEvent";
import {CalendarGeneralEvent} from "./CalendarGeneralEvent";

export const CalendarEventModal = (props:ICalendarEvent) => {
    const {isOpen, toggle} = useModal();
    // @ts-ignore
    const event_date_time = new DateTime.fromISO(props.date_time);

    return (
        <>
            <Button outline onClick={toggle}>
                <div className={"calendar-time"}>
                    {event_date_time.toLocaleString(DateTime.TIME_24_SIMPLE)}
                    <Badge color={props.type==="Installation"?"primary":"info"}>{props.type}</Badge>
                </div>
                <div className={"calendar-title"}>
                    {props.title}
                </div>
            </Button>
            <Modal centered={true} isOpen={isOpen} toggle={toggle}>
                {props.type==="Installation" || props.type==="Lodge Meeting"?<CalendarLodgeEvent {...props}/>:<CalendarGeneralEvent {...props} />}
            </Modal>
        </>
    )
}