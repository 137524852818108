import * as React from 'react';
import {useEffect, useState} from "react";
import {DateTime} from "luxon";

export const useDateSelector = (onChangeFunc:(date:DateTime)=>void, givenDate?:DateTime) => {
    const [date, setDate] = useState(DateTime.now());
    const [daysInMonth, setDaysInMonth] = useState<number[]>([])

    const changeYear = (e:React.ChangeEvent<HTMLInputElement>) => {
        const year = +e.target.value;
        const modifiedDate = date.set({year:year});
        setDate(modifiedDate);
        changeDaysInMonth(modifiedDate.daysInMonth);
    }
    const changeMonth = (e:React.ChangeEvent<HTMLInputElement>) => {
        const month = +e.target.value;
        if(month < 1 || month > 12) {
            return
        }
        const modifiedDate = date.set({month:month});
        setDate(modifiedDate);
        changeDaysInMonth(modifiedDate.daysInMonth);
    }
    const changeDay = (e:React.ChangeEvent<HTMLInputElement>) => {
        const day = +e.target.value;
        const modifiedDate = date.set({day:day});
        setDate(modifiedDate);
    }

    const changeDaysInMonth = (days:number) => {
        const dayValues = [];
        for(let i=0;i<days;i++) dayValues.push(i+1);
        setDaysInMonth(dayValues)
    }

    useEffect(()=>{
        onChangeFunc(date)
        changeDaysInMonth(date.daysInMonth);
    },[date])

    useEffect(()=>{
        if(givenDate) {
            setDate(givenDate);
            changeDaysInMonth(givenDate.daysInMonth)
        }
    }, [])

    useEffect(()=>{
        // if(typeof givenDate !== "undefined") {
        //     console.log('given date changed', givenDate.toLocaleString())
        //     setDate(givenDate);
        //     changeDaysInMonth(givenDate.daysInMonth)
        // }
    }, [givenDate])

    return {date, changeYear, changeMonth, changeDay, daysInMonth}
}