import * as React from 'react';
import {useDropdown} from "../../states/components/useDropdown";
import {Button, Card, CardBody, Collapse} from "reactstrap";

type myType = {
    question: string,
    answer: any
}

export const FAQCollapse = (props:myType) => {
    const {isOpen, toggle} = useDropdown();
    return (
        <div>
            <Button color={"light"} onClick={toggle} className={"p-3 text-left"} block>{props.question}</Button>
            <Collapse isOpen={isOpen}>
                <Card>
                    <CardBody>
                        {props.answer}
                    </CardBody>
                </Card>
            </Collapse>
        </div>
    )
}