import * as React from 'react';
import {useEffect, useState} from "react";
import {useAuthState} from "../../contexts/auth/authContext";
import {IMasonicProfile} from "../../interfaces/User";
import {ILodge} from "../../interfaces/Lodge";
import {DateTime} from "luxon";

export const useMasonicProfileFormOld = ({props, updatePreview}:{props:IMasonicProfile|null, updatePreview?:(profile:IMasonicProfile)=>void}) => {
    const currentUser = useAuthState();
    const [rank, setRank] = useState(props?.rank);
    const [initiation, setInitiation] = useState(props?.initiation);
    const [passing, setPassing] = useState(props?.passing);
    const [raising, setRaising] = useState(props?.raising);
    const [occupation, setOccupation] = useState(props?.occupation);
    const [motherLodge, setMotherLodge] = useState(props?.mother_lodge);
    const [memberOf, setLodges] = useState(props?.lodges);
    const [verified, setVerified] = useState<number | null>();
    const [isVerified, setIsVerified] = useState(false);
    const [masonicProfile, setMasonicProfile] = useState<IMasonicProfile>();

    useEffect(() => {
        if(props?.verified_by !== undefined) {
            setIsVerified(true);
        }
    },[])

    const rankChange = (e:any) => setRank(e.target.value)
    const joinDateChange = (date:DateTime) => setInitiation(date.toLocaleString())
    const changePassing = (date:DateTime) => setPassing(date.toLocaleString());
    const changeRaising = (date:DateTime) => setRaising(date.toLocaleString());
    const changeOccupation = (e:any) => setOccupation(e.target.value);
    const changeMotherLodge = (lodges:ILodge[]) => setMotherLodge(lodges[0]);
    const changeMemberOfLodges = (lodges:ILodge[]) => setLodges(lodges);
    const verifiedChange = (e:any) => {
        if(currentUser.user !== undefined) {
            setVerified(currentUser.user.account.id)
        }
    }

    useEffect(() => {
        const thisProfile = {
            rank:rank?rank:'',
            initiation:initiation?initiation:'',
            passing:passing,
            raising:raising,
            occupation:occupation,
            mother_lodge:motherLodge,
            lodges:memberOf
        }
       if(typeof updatePreview === "function") {
           updatePreview(thisProfile)
       }
       setMasonicProfile(thisProfile);
    }, [
        rank,
        initiation,
        passing,
        raising,
        occupation,
        motherLodge,
        memberOf
    ])

    const submit = () => {

    }

    return {
        rank,
        join_date: initiation,
        initiation,
        passing,
        raising,
        occupation,
        motherLodge,
        memberOf,
        verified,
        isVerified,
        masonicProfile,
        rankChange,
        joinDateChange,
        verifiedChange,
        changePassing,
        changeRaising,
        changeOccupation,
        changeMotherLodge,
        changeMemberOfLodges,
        submit
    }
}