import * as React from 'react';
import {useEffect, useState} from "react";
import Api from "../../config/Api";

export const useCalendarEvents = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        Api
            .get('calendar')
            .then(
                res => {
                    setEvents(res.data)
                }
            )
            .catch(
                err => setEvents([])
            )
    }, [])

    return {events}
}
