import * as React from 'react';
import {ILodge} from "../../interfaces/Lodge";
import {useEffect, useState} from "react";
import Api from "../../config/Api";
import {ITimelineEvent} from "../../interfaces/Timeline";

export type ILodgeState = {
    lodge?: ILodge,
    loading: boolean
}

type MyProps = {
    slug: string
}

export const useLodge = (props:MyProps):ILodgeState => {
    const [lodge, setLodge] = useState<ILodge>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        Api
            .get('lodges/getLodge/'+props.slug)
            .then(
                res => {
                    setLodge(res.data);
                    setLoading(false);
                }
            )
            .catch(
                err => {
                    setLoading(false);
                }
            )
    }, [])

    return {lodge, loading}
}