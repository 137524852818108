import * as React from 'react';
import {UserDashboardTemplate} from "../../components/templates/UserDashboardTemplate";
import ProfileForm from "../../components/user/molecules/ProfileForm";
import {IUser} from "../../interfaces/User";

type myProps = {
    user: IUser|undefined,
    edit_dod: boolean
}

export const ProfileCreatePage = (props:myProps) => {
    const pageContent = (
        <>
            <h1>Profile management</h1>
            <ProfileForm edit_dod={props.edit_dod} self={false}/>
        </>
    )

    return (
        <UserDashboardTemplate content={pageContent}/>
    )
}