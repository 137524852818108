import {IMasonicProfile} from "../../../interfaces/User";
import {useMasonicProfileForm} from "../../../states/masonic_profile/useMasonicProfileForm";
import {Form, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {PortraitForm} from "../../portrait/forms/portrait.form";
import {DefaultPortrait} from "../../../defaults/portrait.default";
import {DateSelector} from "../../misc/forms/organisms/DateSelector";
import {DateTime} from "luxon";

export const MasonicProfileForm = ({givenProfile}:{givenProfile:IMasonicProfile}) => {
    const {
        thisProfile,
        errors,
        updateRaising,
        updateValue,
        updatePassing,
        updateInitiation
    } = useMasonicProfileForm({givenProfile:givenProfile});

    const nowISO = DateTime.now().toISODate();

    return (
        <>
            <Form>
                <FormGroup>
                    <Label for={"rank"}>Rank</Label>
                    <Input type={"text"} name={"rank"} value={givenProfile.rank} onChange={updateValue} placeholder={"Master Mason"} invalid={errors.hasOwnProperty('rank')}/>
                    <FormFeedback>{errors.hasOwnProperty('rank') && errors.rank}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Initiation Date</Label>
                    <DateSelector onChangeFunc={updateInitiation} givenDate={DateTime.fromISO(thisProfile.initiation || nowISO)}/>
                </FormGroup>
                <FormGroup>
                    <Label>Passing Date</Label>
                    <DateSelector onChangeFunc={updatePassing} givenDate={DateTime.fromISO(thisProfile.passing || nowISO)}/>
                </FormGroup>
                <FormGroup>
                    <Label>Raising Date</Label>
                    <DateSelector onChangeFunc={updateRaising} givenDate={DateTime.fromISO(thisProfile.raising || nowISO)}/>
                </FormGroup>
            </Form>
            <h3>Portrait Details</h3>
            <PortraitForm givenPortrait={thisProfile.portrait||DefaultPortrait}/>
        </>
    )
}