import * as React from 'react';
import {FullWidthTemplate} from "../../components/templates/FullWidthTemplate";
import {FAQCollapse} from "../../components/layout/FAQCollapse";

export const AboutPage = () => {
    const questions = [
        {
            question: 'What is Freemasonry?',
            answer: (
                <>
                    <p>Freemasonry means different things to each of those who join. For some, it’s about making new
                        friends and acquaintances. For others it’s about being able to help deserving causes –
                        making a contribution to family and for society. But for most, it is an enjoyable hobby.</p>
                    <p>Freemasonry is one of the world’s oldest and largest non-religious, non-political, fraternal
                        and charitable organisation. It teaches self-knowledge through participation in a
                        progression of ceremonies. Members are expected to be of high moral standing and are
                        encouraged to speak openly about Freemasonry.</p>
                </>
            )
        },
        {
            question:'What happens ata a lodge meeting?',
            answer: (
                <>
                    <p>The meeting, which like those of other groups, are open only to members, is normally in two
                        parts. First, there are normal administrative procedures such as:</p>

                    <ul>
                        <li>Minutes of the previous meeting</li>
                        <li>Proposing and balloting for new members</li>
                        <li>Discussing and voting on the annual accounts</li>
                        <li>Masonic news and correspondence</li>
                        <li>News about charitable work</li>
                    </ul>
                    <p>Second, there are the ceremonies for:</p>
                    <ul>
                        <li>Admitting new members</li>
                        <li>The annual installation of the Master of the Lodge and his officers</li>
                    </ul>
                </>
            )
        },
        {
            question: 'Why do Freemasons take oaths?',
            answer: (
                <p>New members make solemn promises concerning their behaviour both in the Lodge and in society.
                    Members also promise to keep confidential the way they recognise each other when visiting
                    another Lodge. Freemasons also promise to support others in time of need but only so far as
                    it does not conflict with their family and public obligations.</p>
            )
        },
        {
            question: 'Are Freemasons expected to give preference to fellow members?',
            answer: (
                <p>Certainly not. This would be unacceptable and may lead to action being taken against those
                    involved. On joining, each new member states that he expects no material gain from
                    membership.</p>
            )
        },
        {
            question: 'Who do the Masonic charities donate to?',
            answer: (
                <p>Whilst there are Masonic charities that cater specifically, but not exclusively, for Masons
                    or their dependants, others make significant grants to non-Masonic organisations.</p>
            )
        },
        {
            question: 'What is Freemasonry\'s relationship with religion?',
            answer: (
                <p>
                    All Freemasons are expected to have a religious belief, but Freemasonry does not seek to replace
                    a Mason’s religion or provide a substitute for it. It deals in a man’s relationship with his
                    fellow man not in a man’s relationship with his God.
                </p>
            )
        },
        {
            question: 'Why do some churches not like Freemasonry?',
            answer: (
                <p>There are elements within churches who misunderstand Freemasonry and its objectives. They
                    confuse secular rituals with religious liturgy. There are many Masons in churches where
                    their leaders have been openly critical of the organisation. Masonry has always actively
                    encouraged its members to be active in their own religion.</p>
            )
        },
        {
            question: 'Does Freemasonry accept Roman Catholics?',
            answer: (
                <p>Yes. Four Grand Masters of English Freemasonry have been Roman Catholics. Today there are
                    many Roman Catholic Freemasons.</p>
            )
        },
        {
            question: 'What is Freemasonry\'s relationship with politics?',
            answer: (
                <p>Freemasonry, as a body, will never express a view on politics or state policy. The discussion
                    of politics at Masonic meetings has always been prohibited.</p>
            )
        },
        {
            question: 'Is Freemasonry an international order?',
            answer: (
                <p>
                    Freemasonry exists throughout the world. However, each Grand Lodge is sovereign and
                    independent. There is no international governing body for Freemasonry.
                </p>
            )
        },
        {
            question: 'Can anyone become a Freemason?',
            answer: (
                <p>
                    Yes – Freemasonry is open to people from all walks of life, regardless of their race,
                    religion, sexual orientation or socio-economic position in society.
                </p>
            )
        },
        {
            question: 'Are there women Freemasons?',
            answer: (
                <>
                    <p>Yes. Women Freemasons have two separate Grand Lodges.</p>
                    <p>For more information, please contact the <a href="http://www.hfaf.org/">Honourable Fraternity
                        of Ancient Freemasons [HFAF]</a> and the <a href="http://www.owf.org.uk/">Order of Women
                        Freemasons [OWF]</a>.</p>
                    <p>In 2018, in line with the Gender Recognition Act of 2004 and the Equality Act of 2010, UGLE
                        published its gender reassignment policy. The policy outlines that a candidate for admission
                        to Freemasonry under UGLE must be a man. Should a person who has undergone gender
                        reassignment and has become a man apply to become a Freemason then his application must be
                        processed in the same way as for any other male candidate. A Freemason who after initiation
                        ceases to be a man does not cease to be a Freemason.</p>
                    <p><a href="https://www.ugle.org.uk/gender-reassignment-policy">Read UGLE’s gender reassignment
                        policy.</a></p>
                </>
            )
        },
        {
            question: 'Why do you wear regalia?',
            answer: (
                <p>Wearing regalia is historic and symbolic. Like a uniform, the regalia indicates the rank of
                    the wearer in the organisation.</p>
            )
        },
        {
            question: 'How manny Freemasons are there?',
            answer: (
                <>
                    <p>Under the United Grand Lodge of England, there are over 200,000 Freemasons.</p>
                    <p>There are Grand Lodges in Ireland, which covers both Northern Ireland and Eire, and Scotland
                        which have a combined total of approximately 150,000 members.</p>
                    <p>Worldwide, there are approximately six million Freemasons.</p>
                </>
            )
        },
        {
            question: 'How many degrees are there in Freemasonry?',
            answer: (
                <>
                    <p>Basic Freemasonry consists of three degrees:</p>
                    <ul>
                        <li>Entered Apprentice</li>
                        <li>Fellow Craft</li>
                        <li>Master Mason</li>
                    </ul>
                </>
            )
        },
        {
            question: 'How much does it cost to be a Freemason?',
            answer: (
                <>
                    <p>There is an initiation fee when you join and each lodge charges an annual subscription to
                        cover its running costs.</p>

                    <p>Each meeting is normally followed by a dinner, with the cost depending on the venue. However,
                        there is no requirement to attend the dinner if you are unable to. You will also be expected
                        to wear a dark suit, white shirt, and black, or other suitable, tie and in due course you
                        will need to buy your own regalia.</p>

                    <p>Members are invited to donate to charity, but this should always be within your means and it
                        is entirely up to you how much you wish to contribute.</p>

                    <p>Costs can vary considerably from lodge to lodge, particularly for the dining, and your
                        proposer and seconder should make them clear to you before you join.</p>
                </>
            )
        }
    ]

    return (
        <FullWidthTemplate content={
            <>
                <div className={"col-8 mx-auto"}>
                    <h1>About</h1>
                    <div className={"p-2"}>
                        <p>
                            <i>For a comprehensive look at Freemasonry, please visit the <a href={"https://www.ugle.org.uk"}>UGLE website</a></i>
                        </p>
                        <p>
                            Freemasonry means different things to each of those who join. For some, it’s about making new friends and acquaintances. For others it’s about being able to help deserving causes – making a contribution to family and for society. But for most, it is an enjoyable hobby.
                        </p>
                        <p>
                            Freemasonry is one of the world’s oldest and largest non-religious, non-political, fraternal and charitable organisation. It teaches self-knowledge through participation in a progression of ceremonies. Members are expected to be of high moral standing and are encouraged to speak openly about Freemasonry.
                        </p>
                        <p>
                            <i>The following information is provided by <a href="https://www.ugle.org.uk/about-freemasonry/frequently-asked-questions">UGLE FAQs</a></i>
                        </p>
                        <h2>Frequently Asked Questions</h2>
                    </div>
                    {questions.map(q => {return <FAQCollapse {...q}/>})}
                </div>
            </>
        }/>
    )
}